import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_SUB_CATEGORY = 'GET_SUB_CATEGORY';
export const GET_SUB_CATEGORY_SUCCESS = 'GET_SUB_CATEGORY_SUCCESS';
export const GET_SUB_CATEGORY_FAILURE = 'GET_SUB_CATEGORY_FAILURE';

// Action Creators
export const fetchGetSubCategory = () => ({
  type: GET_SUB_CATEGORY,
});

export const fetchGetSubCategorySuccess = (data) => ({
  type: GET_SUB_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchGetSubCategoryFailure = (error) => ({
  type: GET_SUB_CATEGORY_FAILURE,
  payload: error,
});

// Thunk Function
export const getSubCategoryCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetSubCategory(data, external));
    try {
      const response = await getMethodWithToken(
        url.getSubCategoryListSuperAdmin + '?query=' + external?.slug
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetSubCategorySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetSubCategoryFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetSubCategoryFailure(error.message));
    }
  };
};
