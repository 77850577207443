import { putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

// Action Creators
export const fetchUpdateProfile = () => ({
  type: UPDATE_PROFILE,
});

export const fetchUpdateProfileSuccess = (data) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: data,
});

export const fetchUpdateProfileFailure = (error) => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: error,
});

// Thunk Function
export const updateProfileCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchUpdateProfile(data, external));
    try {
      const response = await putMethodWithToken(
        url.updateProfileProviderAdmin,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchUpdateProfileSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchUpdateProfileFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchUpdateProfileFailure(error.message));
    }
  };
};
