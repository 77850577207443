import { postMethodWithTokenWIthFormData } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

// Action Creators
export const fetchAddCategory = () => ({
  type: ADD_CATEGORY,
});

export const fetchAddCategorySuccess = (data) => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchAddCategoryFailure = (error) => ({
  type: ADD_CATEGORY_FAILURE,
  payload: error,
});

// Thunk Function
export const addCategoryCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchAddCategory(data, external));
    try {
      const response = await postMethodWithTokenWIthFormData(
        url.addCategoryListSuperAdmin,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchAddCategorySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchAddCategoryFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchAddCategoryFailure(error.message));
    }
  };
};
