import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';

// Action Types
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS';
export const GET_CUSTOMER_DETAILS_FAILURE = 'GET_CUSTOMER_DETAILS_FAILURE';

// Action Creators
export const fetchGetCustomerDetails = () => ({
  type: GET_CUSTOMER_DETAILS,
});

export const fetchGetCustomerDetailsSuccess = (data) => ({
  type: GET_CUSTOMER_DETAILS_SUCCESS,
  payload: data,
});

export const fetchGetCustomerDetailsFailure = (error) => ({
  type: GET_CUSTOMER_DETAILS_FAILURE,
  payload: error,
});

// Thunk Function
export const getCustomerDetailsCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetCustomerDetails(data, external));
    try {
      const response = await getMethodWithToken(
        url.getCustomerDetailDataSuperAdmin + data?.id + '/details/'
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetCustomerDetailsSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetCustomerDetailsFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetCustomerDetailsFailure(error.message));
    }
  };
};
