import {
  deleteMethodWithToken,
  putMethodWithToken,
} from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const CANCEL_APPOINTMENT_PROVIDER_ADMIN =
  'CANCEL_APPOINTMENT_PROVIDER_ADMIN';
export const CANCEL_APPOINTMENT_PROVIDER_ADMIN_SUCCESS =
  'CANCEL_APPOINTMENT_PROVIDER_ADMIN_SUCCESS';
export const CANCEL_APPOINTMENT_PROVIDER_ADMIN_FAILURE =
  'CANCEL_APPOINTMENT_PROVIDER_ADMIN_FAILURE';

// Action Creators
export const fetchCancelAppointmentProviderAdmin = () => ({
  type: CANCEL_APPOINTMENT_PROVIDER_ADMIN,
});

export const fetchCancelAppointmentProviderAdminSuccess = (data) => ({
  type: CANCEL_APPOINTMENT_PROVIDER_ADMIN_SUCCESS,
  payload: data,
});

export const fetchCancelAppointmentProviderAdminFailure = (error) => ({
  type: CANCEL_APPOINTMENT_PROVIDER_ADMIN_FAILURE,
  payload: error,
});

const getRole = (userRole, external) => {
  switch (userRole) {
    case 'company-admin':
      return url.cancelAppointmentDashboardProviderAdmin + external?.id + '/';
    case 'salon-admin':
      return url.cancelAppointmentDashboardProviderAdmin + external?.id + '/';
    case 'branch-company':
      return url.cancelAppointmentDashboardProviderAdmin + external?.id + '/';
    case 'specialist-admin':
      return (
        url.cancelAppointmentSpecialistDashboardProviderAdmin +
        external?.id +
        '/'
      );
  }
};

// Thunk Function
export const cancelAppointmentProviderAdminCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchCancelAppointmentProviderAdmin(data, external));
    try {
      const userRole = getUserRole();
      const response = await putMethodWithToken(getRole(userRole, external));
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchCancelAppointmentProviderAdminSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchCancelAppointmentProviderAdminFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchCancelAppointmentProviderAdminFailure(error.message));
    }
  };
};
