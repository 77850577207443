import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import {
  providerAdminBranches,
  providerAdminBranchesDetails,
} from 'routes/AppUrls';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import ReusableButton from 'components/common/Button';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { countryCityDropdownCallAPI } from 'store/CountryCityList/action';
import { imageUploadCallAPI } from 'store/ImageUpload/action';
import ToastNotification from 'components/common/Toast';
import CheckValidation from 'utils/Validation';
import { addCompanyBranchProviderCallAPI } from 'store/ProviderAdmin/addCompanyBranchProvider/action';
import CircularProgress from '@mui/material/CircularProgress';
import { updateCompanyBranchProviderCallAPI } from 'store/ProviderAdmin/updateCompanyBranchProvider/action';
import Geosuggest from 'react-geosuggest';
import { useLoadScript } from '@react-google-maps/api';
import env from 'utils/environment/env.json';

const libraries = ['places'];

const AddBranches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [conf, setConf] = ToastNotification();
  const { locale } = useContext(LocaleContext);

  const branchImageRef = useRef();
  const phoneNumberInputRef = useRef(null);
  const alternatePhoneNumberInputRef = useRef(null);
  const bussinessPhoneNumberInputRef = useRef(null);
  const [isError, setIsError] = useState(null);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isAlternatePhoneNumberValid, setIsAlternatePhoneNumberValid] =
    useState(true);
  const [isBussinessPhoneNumberValid, setIsBussinessPhoneNumberValid] =
    useState(true);

  const profileData = useSelector((state) => state?.userMeReducer?.data?.data);
  const countryCityData = useSelector(
    (state) => state?.countryCityDropdownReducer?.data?.data
  );
  const addBranchLoader = useSelector(
    (state) => state?.addCompanyBranchProviderReducer?.loading
  );
  const updateBranchLoader = useSelector(
    (state) => state?.updateCompanyBranchProviderReducer?.loading
  );
  const { state } = useLocation();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env?.googleApiKey,
    libraries,
  });

  const [formValue, setFormValue] = useState({
    searchBussinessName:
      state?.value !== 'add'
        ? state?.value?.branch_details?.searched_business_name_en
        : '',
    addressArea:
      state?.value !== 'add' ? state?.value?.branch_details?.address : '',
    country:
      state?.value !== 'add' ? state?.value?.branch_details?.country?.name : '',
    city:
      state?.value !== 'add' ? state?.value?.branch_details?.city?.name : '',
    phoneNumber:
      state?.value !== 'add'
        ? state?.value?.branch_details?.contact_number
        : '',
    countryCode: '+974',
    alternatePhoneNumber: '',
    countryCodeAlternateNumber: '+974',
    email: state?.value !== 'add' ? state?.value?.branch_details?.email : '',
    alternateEmail: '',
    about:
      state?.value !== 'add'
        ? state?.value?.about_data?.about_information_en
        : '',
    bussinessName:
      state?.value !== 'add'
        ? state?.value?.branch_details?.branch_manager_name_en
        : '',
    bussinessEmail:
      state?.value !== 'add'
        ? state?.value?.branch_details?.branch_manager_email
        : '',
    bussinessImages:
      state?.value !== 'add' ? state?.value?.about_data?.bussiness_images : [],
    countryCodeBussinessPhoneNumber: '+974',
    bussinessPhoneNumber:
      state?.value !== 'add'
        ? state?.value?.branch_details?.branch_manager_contact_number
        : '',
  });

  const [formId, setFormId] = useState({
    country:
      state?.value !== 'add' ? state?.value?.branch_details?.country?.id : '',
    city: state?.value !== 'add' ? state?.value?.branch_details?.city?.id : '',
  });

  useEffect(() => {
    getCountryCityList();
  }, []);

  const getCountryCityList = () => {
    dispatch(countryCityDropdownCallAPI({}, { naviagte: navigate }));
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Branches" />,
      Link: providerAdminBranches(locale),
      isDisbale: false,
    },
    ...(state?.value !== 'add'
      ? [
          {
            title: <FormattedMessage id="Branch Details" />,
            Link: providerAdminBranchesDetails(locale),
            isDisable: false,
          },
        ]
      : []),
    {
      title:
        state?.value === 'add' ? (
          <FormattedMessage id="Add Branch" />
        ) : (
          <FormattedMessage id="Edit Branch" />
        ),
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleCountryCodeChange = (value, name) => {
    if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: value });
    } else if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: value });
    } else {
      setFormValue({ ...formValue, countryCodeBussinessPhoneNumber: value });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value?.name });
    setFormId({ ...formId, [name]: value?.id });
  };

  const handleBranchImageUpload = () => {
    branchImageRef.current.click();
  };

  const handleBranchImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSalonImagesSuccess(res),
        })
      );
    }
  };

  const handleSalonImagesSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue((prevState) => ({
      ...prevState,
      bussinessImages: [
        ...(prevState?.bussinessImages || []),
        { image: res?.data?.data?.[0]?.filePath, id: res?.data?.data?.[0]?.id },
      ],
    }));
  };

  const handleDeleteBranchImage = (data, index) => {
    const filterImages = formValue?.bussinessImages?.filter(
      (value) => value !== data
    );
    setFormValue({
      ...formValue,
      ['bussinessImages']: filterImages,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumberValue = e.target.value;
    setFormValue((prev) => ({
      ...prev,
      [e.target.name]: phoneNumberValue,
    }));

    if (e.target.name === 'phoneNumber') {
      const fullPhoneNumber = `+${formValue?.countryCode}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsPhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsPhoneNumberValid(false);
      }
    } else if (e.target.name === 'alternatePhoneNumber') {
      const fullPhoneNumber = `+${formValue?.countryCodeAlternateNumber}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsAlternatePhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsAlternatePhoneNumberValid(false);
      }
    } else {
      const fullPhoneNumber = `+${formValue?.countryCodeBussinessPhoneNumber}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsBussinessPhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsBussinessPhoneNumberValid(false);
      }
    }
  };

  const handleAddBranch = () => {
    const {
      searchBussinessName,
      country,
      city,
      phoneNumber,
      email,
      bussinessName,
      bussinessEmail,
      bussinessPhoneNumber,
    } = formValue;
    const error = CheckValidation({
      searchBussinessName,
      country,
      city,
      phoneNumber,
      email,
      bussinessName,
      bussinessEmail,
      bussinessPhoneNumber,
    });
    setIsError(error);
    if (error.submit) {
      let body = {
        business_type: 'company',
        searched_business_name_en: formValue?.searchBussinessName,
        searched_business_name_ar: formValue?.searchBussinessName,
        address: formValue?.addressArea,
        country: formId?.country,
        city: formId?.city,
        contact_number: formValue?.phoneNumber,
        is_contact_verified: false,
        alternate_contact_number: formValue?.alternatePhoneNumber,
        is_alternate_contact_verified: false,
        email: formValue?.email,
        is_email_verified: false,
        alternate_email: formValue?.alternateEmail,
        is_alternate_email_verified: false,
        about_information_en: formValue?.about,
        about_information_ar: formValue?.about,
        branch_images:
          formValue?.bussinessImages?.length > 0
            ? formValue?.bussinessImages.map((k, i) => k?.id)
            : [],
        branch_manager_name_en: formValue?.bussinessName,
        branch_manager_name_ar: formValue?.bussinessName,
        branch_manager_contact_number: formValue?.bussinessPhoneNumber,
        branch_manager_email: formValue?.bussinessEmail,
      };
      if (state?.value === 'add') {
        dispatch(
          addCompanyBranchProviderCallAPI(body, {
            run: true,
            id: profileData?.provider_details?.id,
            handleFunction: (res) => handleRedirect(res),
            navigate: navigate,
            toast: true,
            handleToastError: (res) => handleError(res),
          })
        );
      } else {
        dispatch(
          updateCompanyBranchProviderCallAPI(body, {
            run: true,
            id: state?.value?.branch_details?.id,
            handleFunction: (res) => handleRedirect(res),
            navigate: navigate,
            toast: true,
            handleToastError: (res) => handleError(res),
          })
        );
      }
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setTimeout(() => {
      navigate(providerAdminBranches(locale));
    }, 1000);
  };

  const handleError = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
  };

  const handleBusinesChanged = (e) => {
    setFormValue((prevState) => ({
      ...prevState,
      ['searchBussinessName']: e,
    }));
  };

  const onSuggestSelect = (suggest) => {
    if (suggest) {
      // Business Name
      setFormValue((prevState) => ({
        ...prevState,
        searchBussinessName: suggest.gmaps.name,
      }));

      ///////////About
      setFormValue((prevState) => ({
        ...prevState,
        about: suggest.description,
      }));

      // Address
      setFormValue((prevState) => ({
        ...prevState,
        addressArea: suggest.gmaps.formatted_address,
      }));

      // Country,City Data
      const components = suggest.gmaps.address_components;

      let country_name = '';
      let country_id = '';
      let city_name = '';

      for (let key of components) {
        if (key.types[0] === 'country') {
          country_name = key.long_name;
        }
        if (key.types[0] === 'locality') {
          city_name = key.long_name;
        }
      }

      if (country_name) {
        let indexCountry = countryCityData.find(
          (obj) => obj.name === country_name
        );

        country_name = indexCountry.name;
        country_id = indexCountry?.id;

        setFormValue((prevState) => ({
          ...prevState,
          country: country_name,
        }));

        setFormId((prevState) => ({
          ...prevState,
          country: country_id,
        }));
      }

      if (country_id) {
        let indexCountry = countryCityData.findIndex(
          (obj) => obj.name === country_name
        );
        let indexCity = countryCityData?.[indexCountry]?.city.find(
          (value) => value?.name === city_name
        );
        setFormValue((prevState) => ({
          ...prevState,
          city: indexCity?.name,
        }));

        setFormId((prevState) => ({
          ...prevState,
          city: indexCity?.id,
        }));
      }

      if (suggest.gmaps.international_phone_number) {
        if (suggest.gmaps.international_phone_number) {
          const phoneDetails = parsePhoneNumberFromString(
            suggest.gmaps.international_phone_number
          );
          if (phoneDetails !== undefined) {
            setFormValue((prevState) => ({
              ...prevState,
              countryCode: `+${phoneDetails.countryCallingCode}`,
            }));

            setFormValue((prevState) => ({
              ...prevState,
              phoneNumber: phoneDetails.nationalNumber,
            }));
          }
        }
      }

      // Lat Long set
      setFormValue((prevState) => ({
        ...prevState,
        latitude: suggest.location.lat,
      }));

      setFormValue((prevState) => ({
        ...prevState,
        longitude: suggest.location.lng,
      }));

      // Photos
      const busPhotos = suggest.gmaps.photos;
      if (suggest) {
        busPhotos &&
          busPhotos.length > 0 &&
          busPhotos.forEach(async (data, index) => {
            let photoUrl = data.getUrl();
            let formData = new FormData();
            formData.append('filePath', photoUrl);
            formData.append('fileType', 'U');
            dispatch(
              imageUploadCallAPI(formData, {
                navigate: navigate,
                run: true,
                handleFunction: (res) => handleSalonImagesSuccess(res),
              })
            );
          });
      }
    }
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(-1)}
                />
                <p className="p-0 m-0">
                  {state?.value === 'add' ? (
                    <FormattedMessage id="Add Branch" />
                  ) : (
                    <FormattedMessage id="Edit Branch" />
                  )}
                </p>
              </Stack>
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          <div className="mt-4">
            <p className="p-0 m-0 fc-292D32 fs-18 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Branch Details" />
            </p>
          </div>

          <Row className="m-0 p-0">
            <Col xs={12} md={6}>
              {isLoaded && (
                <>
                  <p className="businessLabel">
                    <FormattedMessage id="Search business name" />
                  </p>
                  <div className="geosuggestInput">
                    <Geosuggest
                      onChange={(e) => handleBusinesChanged(e)}
                      onSuggestSelect={onSuggestSelect}
                      initialValue={formValue?.searchBussinessName}
                      placeholder={intl.formatMessage({
                        id: 'Search business name',
                      })}
                      types={['establishment']}
                      country={['IN', 'SA']}
                      errorText={
                        isError &&
                        isError.searchBussinessName.error &&
                        isError.searchBussinessName.errorMessage
                      }
                    />
                  </div>
                </>
              )}
            </Col>

            <Col xs={12} md={6} className=""></Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Address of the serving area" />}
                placeholder={intl.formatMessage({
                  id: 'Address of the serving area',
                })}
                name="addressArea"
                type="text"
                value={formValue?.addressArea}
                onChange={handleChange}
              />
            </Col>

            <Col xs={12} md={3} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="Country" />}
                placeholder={intl.formatMessage({ id: 'Country' })}
                name={'country'}
                value={formValue?.country}
                data={countryCityData}
                onChange={(e) => handleInputChange(e)}
                errorText={
                  isError &&
                  isError.country.error &&
                  isError.country.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={3} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="City" />}
                placeholder={intl.formatMessage({ id: 'City' })}
                name={'city'}
                value={formValue?.city}
                data={
                  countryCityData?.find(
                    (value) => value?.name === formValue?.country
                  )?.city || []
                }
                onChange={(e) => handleInputChange(e)}
                errorText={
                  isError &&
                  isError.country.error &&
                  isError.country.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label={<FormattedMessage id="Contact Number" />}
                country={'qa'}
                value={formValue?.countryCode}
                name={'phoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'phoneNumber')
                }
                onPhoneNumberChange={handlePhoneNumberChange}
                phoneNumber={formValue.phoneNumber}
                phoneNumberRef={phoneNumberInputRef}
                isPhoneNumberValid={isPhoneNumberValid}
                isError={
                  isError &&
                  isError.phoneNumber.error &&
                  isError.phoneNumber.errorMessage
                }
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label={<FormattedMessage id="Alternate Contact Number" />}
                country={'qa'}
                value={formValue?.countryCodeAlternateNumber}
                name={'alternatePhoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'alternatePhoneNumber')
                }
                onPhoneNumberChange={handlePhoneNumberChange}
                phoneNumber={formValue.alternatePhoneNumber}
                phoneNumberRef={alternatePhoneNumberInputRef}
                isPhoneNumberValid={isAlternatePhoneNumberValid}
                // isError={isError}
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Email ID" />}
                placeholder={intl.formatMessage({ id: 'Email ID' })}
                name="email"
                type="text"
                value={formValue?.email}
                onChange={handleChange}
                errorText={
                  isError && isError.email.error && isError.email.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Alternate Email ID" />}
                placeholder={intl.formatMessage({
                  id: 'Alternate Email ID',
                })}
                name="alternateEmail"
                type="text"
                value={formValue?.alternateEmail}
                onChange={handleChange}
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="About/Information" />}
                placeholder={intl.formatMessage({ id: 'About/Information' })}
                height="100"
                multiline
                name="about"
                value={formValue?.about}
                onChange={(e) => handleChange(e)}
              />
            </Col>

            <Col xs={12} md={12} className="mt-4">
              <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Branch Manager" />
              </p>
            </Col>

            <Col xs={12} md={6} className="mt-1">
              <LabelWithInput
                label={<FormattedMessage id="Name" />}
                placeholder={intl.formatMessage({ id: 'Name' })}
                name="bussinessName"
                type="text"
                value={formValue?.bussinessName}
                onChange={handleChange}
                errorText={
                  isError &&
                  isError.bussinessName.error &&
                  isError.bussinessName.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={6} className=""></Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label={<FormattedMessage id="Contact Number" />}
                country={'qa'}
                value={formValue?.countryCodeBussinessPhoneNumber}
                name={'bussinessPhoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'bussinessPhoneNumber')
                }
                onPhoneNumberChange={handlePhoneNumberChange}
                phoneNumber={formValue.bussinessPhoneNumber}
                phoneNumberRef={bussinessPhoneNumberInputRef}
                isPhoneNumberValid={isBussinessPhoneNumberValid}
                isError={
                  isError &&
                  isError.bussinessPhoneNumber.error &&
                  isError.bussinessPhoneNumber.errorMessage
                }
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Email" />}
                placeholder={intl.formatMessage({ id: 'Email' })}
                name="bussinessEmail"
                type="text"
                value={formValue?.bussinessEmail}
                onChange={handleChange}
                errorText={
                  isError &&
                  isError.bussinessEmail.error &&
                  isError.bussinessEmail.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <div className="flex justify-between items-center">
                <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Images" />
                </p>
                {formValue?.bussinessImages?.length > 0 && (
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#292D32"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="transparent"
                    fontFamily="Akkurat"
                    onClick={handleBranchImageUpload}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      className="me-2"
                    />
                    <FormattedMessage id="Add Images" />
                  </ReusableButton>
                )}
              </div>

              <input
                ref={branchImageRef}
                type="file"
                accept=".png, .jpg, .jpeg"
                disabled={false}
                style={{ display: 'none' }}
                onChange={handleBranchImageChange}
              />

              {formValue?.bussinessImages?.length <= 0 ? (
                <div className="mt-2 bussinessImages flex justify-center align-center">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={handleBranchImageUpload}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="AddImageEn" />
                    </p>
                  </Stack>
                </div>
              ) : (
                <div className="flex flex-wrap gap-2 mt-3">
                  {formValue.bussinessImages.map((key, index) => (
                    <div className="relative">
                      <img
                        src={key?.image}
                        alt="image"
                        style={{ height: '122px', width: '246px' }}
                      />
                      <img
                        src={crossIcon}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        className="absolute top-2 right-2 cursor-pointer"
                        onClick={() => handleDeleteBranchImage(key, index)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-4 items-end m-0 p-0">
            <div className="flex justify-end">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                  onClick={() => navigate(providerAdminBranches(locale))}
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 35px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                  endIcon={
                    (addBranchLoader || updateBranchLoader) && (
                      <CircularProgress
                        style={{
                          color: 'white',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )
                  }
                  onClick={() => handleAddBranch()}
                >
                  <FormattedMessage id="Next" />
                </ReusableButton>
              </Stack>
            </div>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default AddBranches;
