import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { providerAdminCustomer } from 'routes/AppUrls';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import PhotoImage from 'assets/images/provider/image4.png';
import CaretUpIcon from 'assets/images/Category/CaretUp.png';
import CaretDownIcon from 'assets/images/Category/CaretDown.png';
import HouseIcon from 'assets/images/provider/HouseLine.png';
import StarIcon from '@mui/icons-material/Star';
import { getUserRole } from 'utils/general';
import Avtar from 'assets/images/Dashboard/avtar.png';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetailProviderCallAPI } from 'store/ProviderAdmin/customerDetails/action';
import moment from 'moment';
import Lottie from 'lottie-react';
import animationData from 'assets/Lottie/no_data.json';

const ProviderCustomerDetails = () => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [expandedId, setExpandedId] = useState(null);
  const { state } = useLocation();

  const customerDetailResponse = useSelector(
    (state) => state?.getCustomerDetailProviderReducer?.data?.data
  );
  const [customerDetailData, setCustomerDetailData] = useState(
    customerDetailResponse ? customerDetailResponse : ''
  );

  const toggleExpand = (id) => {
    setExpandedId((prev) => (prev === id ? null : id));
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Customers" />,
      Link: providerAdminCustomer(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Customer Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  useEffect(() => {
    getCustomerDetailsApi();
  }, []);

  const getCustomerDetailsApi = () => {
    dispatch(
      getCustomerDetailProviderCallAPI(
        { id: state?.id },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setCustomerDetailData(res?.data?.data);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <Col xs={12} md={6} className="mt-4">
            <div className="gap-3 flex">
              <img
                src={
                  (customerDetailData?.profile_image !== null ||
                    customerDetailData?.user_details?.profile_image !== null) &&
                  (customerDetailData?.profile_image !== undefined ||
                    customerDetailData?.user_details?.profile_image !==
                      undefined)
                    ? customerDetailData?.profile_image ||
                      customerDetailData?.user_details?.profile_image
                    : PhotoImage
                }
                alt={'icon'}
                style={{
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%',
                }}
              />
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {customerDetailData?.name ||
                      customerDetailData?.user_details?.name}
                  </p>
                </div>
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    ID:
                    {customerDetailData?.id ||
                      customerDetailData?.user_details?.customer_id}
                  </p>
                </div>
                <div className="flex gap-5">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {customerDetailData?.contact_number ||
                        customerDetailData?.user_details?.contact_number}
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {customerDetailData?.email ||
                        customerDetailData?.user_details?.email}
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} className="mt-4 d-flex items-end">
            <div>
              <p className="p-0 m-0 fs-16 fw-400 fc-9A9A9A ff-Akkurat_Bold">
                <FormattedMessage id="Location" /> {':'}
              </p>
              <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat">
                {customerDetailData?.address ||
                  customerDetailData?.user_details?.address}
              </p>
            </div>
          </Col>
        </Row>

        <h5 className="p-0 m-0 mt-4 fs-16 fw-400 fc-292D32 ff-Akkurat_Bold">
          <FormattedMessage id="Appointment History" />
        </h5>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          {userRole === 'company-admin' && (
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Branch Name" />
              </p>
            </Col>
          )}
          {userRole !== 'specialist-admin' && (
            <Col
              lg={`${userRole !== 'specialist-admin' ? 2 : 3}`}
              md={6}
              sm={12}
            >
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Specialist" />
              </p>
            </Col>
          )}
          <Col lg={`${userRole === 'specialist-admin' ? 3 : 2}`} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col
            lg={`${userRole === 'specialist-admin' ? 3 : userRole === 'company-admin' ? 1 : 2}`}
            md={6}
            sm={12}
          >
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={`${userRole === 'company-admin' ? 1 : 2}`} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Price" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="m-0 p-0 mt-3 flex items-center">
          {customerDetailData?.appointment_history?.length > 0 ? (
            customerDetailData.appointment_history.map((item, index) => (
              <Row
                key={item.id}
                className={`m-0 p-0 flex items-center ${customerDetailData?.appointment_history?.length === index + 1 ? 'py-4' : 'border-b py-4'} ${expandedId === item.id ? 'bc-F6F6F6' : 'bc-FFFFFF'}`}
              >
                <Col md={2} className="items-center">
                  <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                    {item.booking_id}
                  </p>
                </Col>

                {userRole === 'company-admin' && (
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {item?.branches}
                    </p>
                  </Col>
                )}

                {userRole !== 'specialist-admin' && (
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <img
                        src={Avtar}
                        alt="icon"
                        style={{ height: '40px', width: '40px' }}
                      />
                      <div>
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {item?.specialist}
                        </p>
                        <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                          {item?.specialist}
                        </p>
                      </div>
                    </Stack>
                  </Col>
                )}

                <Col md={`${userRole === 'specialist-admin' ? 3 : 2}`}>
                  <div className="flex-col items-center">
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center "
                    >
                      <img
                        src={HouseIcon}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                      <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                        {item?.service_location}
                      </p>
                    </Stack>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {item.delivery_address}
                    </p>
                  </div>
                </Col>

                <Col
                  md={`${userRole === 'specialist-admin' ? 3 : userRole === 'company-admin' ? 1 : 2}`}
                >
                  <div className="flex-col items-center">
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {item.slot_date
                        ? moment(item.slot_date).format('DD MMM YYYY')
                        : ''}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {item.slot_start_time
                        ? moment(item.slot_start_time).format('hh:mm A')
                        : ''}
                    </p>
                  </div>
                </Col>

                <Col md={`${userRole === 'company-admin' ? 1 : 2}`}>
                  <div className="flex-col items-center">
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      {item.prize} {item?.currency}
                    </p>
                  </div>
                </Col>

                <Col md={2} className="text-start">
                  {item.services?.length > 0 && (
                    <button
                      className="expand-btn text-lg"
                      onClick={() => toggleExpand(item.booking_id)}
                      aria-label={`Toggle sub-services for ${item.booking_id}`}
                    >
                      {expandedId === item.booking_id ? (
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center cursor-pointer"
                        >
                          <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                            <FormattedMessage id="Hide Services" />
                          </p>
                          <img
                            src={CaretUpIcon}
                            alt={'icon'}
                            style={{ height: '24px', width: '24px' }}
                          />
                        </Stack>
                      ) : (
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center cursor-pointer"
                        >
                          <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                            <FormattedMessage id="View Services" />
                          </p>
                          <img
                            src={CaretDownIcon}
                            alt={'icon'}
                            style={{ height: '24px', width: '24px' }}
                          />
                        </Stack>
                      )}
                    </button>
                  )}
                </Col>

                <Row className="m-0 p-0">
                  {expandedId === item.booking_id &&
                    item.services?.length > 0 && (
                      <>
                        <Row className="mt-4 listHeader m-0 p-0 flex items-center">
                          <Col lg={3} md={6} sm={12}>
                            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                              <FormattedMessage id="Services Booked" />
                            </p>
                          </Col>
                          <Col lg={3} md={6} sm={12}>
                            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                              <FormattedMessage id="Rating" />
                            </p>
                          </Col>
                          <Col lg={3} md={6} sm={12}>
                            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                              <FormattedMessage id="Time" />
                            </p>
                          </Col>
                          <Col lg={3} md={6} sm={12}>
                            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                              <FormattedMessage id="Price" />
                            </p>
                          </Col>
                        </Row>

                        {item.services?.length &&
                          item.services.map((sub, i) => (
                            <Row
                              key={sub.service_id}
                              className={`m-0 p-0 flex items-center ${item.services?.length === i + 1 ? 'pt-4' : 'border-b py-4'}`}
                            >
                              <Col lg={3} md={6} sm={12}>
                                <Stack
                                  direction="horizontal"
                                  gap={3}
                                  className="items-center cursor-pointer"
                                >
                                  <img
                                    src={PhotoImage}
                                    alt={'icon'}
                                    style={{
                                      height: '48px',
                                      width: '48px',
                                      borderRadius: '50%',
                                    }}
                                  />
                                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                                    {sub?.service_name}
                                  </p>
                                </Stack>
                              </Col>

                              <Col lg={3} md={6} sm={12}>
                                <Stack
                                  direction="horizontal"
                                  gap={2}
                                  className="items-center cursor-pointer"
                                >
                                  <div className={'starfill'}>
                                    <StarIcon fontSize="medium" />
                                  </div>

                                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                                    {sub?.rating}
                                  </p>
                                </Stack>
                              </Col>

                              <Col lg={3} md={6} sm={12}>
                                <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat">
                                  {sub?.time_required_in_mints} min
                                </p>
                              </Col>

                              <Col lg={3} md={6} sm={12}>
                                <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat_Bold">
                                  {sub?.price} {sub?.currency}
                                </p>
                              </Col>
                            </Row>
                          ))}
                      </>
                    )}
                </Row>
              </Row>
            ))
          ) : (
            <Row className="bc-FFFFFF m-0 p-50 flex justify-center items-center">
              <Lottie
                animationData={animationData}
                loop={true}
                style={{ height: '200px', width: '200px' }}
              />
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderCustomerDetails;
