import React, { useContext, useRef, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import 'assets/styles/common.scss';
import CheckValidation from 'utils/Validation';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import { superAdminDashboard } from 'routes/AppUrls';
import { FormattedMessage, useIntl } from 'react-intl';
import welcomeLogo from 'assets/images/login/allueLogoWelcome.png';
import ReusableButton from 'components/common/Button';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import ReusableTabs from 'components/common/Tabs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { registerInviteBusinessCallAPI } from 'store/ProviderAdmin/registerInviteBusiness/action';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const GetStarted = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const [isError, setIsError] = useState(false);
  const phoneNumberInputRef = useRef(null);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [tab, setTab] = useState(1);

  const inviteSendLoader = useSelector(
    (state) => state?.registerInviteBusinessReducer?.loading
  );

  const [formValue, setformValue] = useState({
    name: '',
    email: '',
    countryCode: '+974',
    phoneNumber: '',
  });

  const handleSubmit = () => {
    const { name, email, phoneNumber } = formValue;
    const error = CheckValidation({ name, email, phoneNumber });
    setIsError(error);
    if (error.submit && isPhoneNumberValid) {
      let body = {
        business_type:
          tab === 1 ? 'specialist' : tab === 2 ? 'salon' : 'company',
        name_en: formValue?.name,
        name_ar: formValue?.name,
        contact_number: formValue.phoneNumber,
        email: formValue.email,
        country_code: formValue?.countryCode,
      };
      dispatch(
        registerInviteBusinessCallAPI(body, {
          run: true,
          toast: true,
          handleFunction: (res) => handleRedirect(res),
          handleToastError: (res) => handleError(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setTimeout(() => {
      navigate(superAdminDashboard(locale));
    }, 1000);
  };

  const handleError = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCountryCodeChange = (value) => {
    setformValue({ ...formValue, countryCode: `+${value}` });
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumberValue = e.target.value;
    setformValue((prev) => ({
      ...prev,
      [e.target.name]: phoneNumberValue,
    }));

    const fullPhoneNumber = `${formValue?.countryCode}${phoneNumberValue}`;
    const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

    if (parsedPhoneNumber) {
      setIsPhoneNumberValid(parsedPhoneNumber.isValid());
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  const tabData = [
    { label: intl.formatMessage({ id: 'Specialist' }) },
    { label: intl.formatMessage({ id: 'Salon' }) },
    { label: intl.formatMessage({ id: 'Company' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };

  return (
    <Container fluid>
      <Row
        className="bc-F2F3F6 d-flex justify-center items-center"
        style={{ height: '100vh' }}
      >
        <Col xs={12} md={4} lg={4} xxl={5} className="m-auto ">
          <Row className="d-flex justify-center items-center">
            <div className="flex-col items-center">
              <div>
                <img src={welcomeLogo} alt="logo" />
              </div>
              <h2 className="fc-292D32 mt-3 fs-40 fw-400 ff-Akkurat_Bold p-0 m-0">
                <FormattedMessage id="Welcome" />
              </h2>
            </div>
          </Row>
        </Col>

        <Col xs={12} md={8} lg={8} xxl={5} className="m-auto">
          <Row className="p-60 m-2 d-flex justify-center items-center bc-FFFFFF">
            <div className="flex items-center">
              <div>
                <h2 className="fc-2C2C2C fs-32 fw-400 ff-Akkurat_Bold p-0 m-0">
                  <FormattedMessage id="Get Started" />
                </h2>
                <p className="fc-6B788E fs-16 fw-400 ff-Akkurat p-0 m-0 mt-2">
                  <FormattedMessage id="Rutrum arcu mauris diam ultrices id aliquet turpis." />
                </p>
              </div>
            </div>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Name" />}
                placeholder={intl.formatMessage({ id: 'Name' })}
                name="name"
                type="text"
                value={formValue?.name}
                onChange={handleInputChange}
                errorText={
                  isError && isError.name.error && isError.name.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="E-mail Address" />}
                placeholder={intl.formatMessage({ id: 'E-mail Address' })}
                name="email"
                type="text"
                value={formValue?.email}
                onChange={handleInputChange}
                errorText={
                  isError && isError.email.error && isError.email.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={12} className="mt-2">
              <PhoneNumberInput
                label={<FormattedMessage id="Contact Number" />}
                country={'qa'}
                value={formValue?.countryCode}
                name={'phoneNumber'}
                onCountryCodeChange={handleCountryCodeChange}
                onPhoneNumberChange={handlePhoneNumberChange}
                phoneNumber={formValue.phoneNumber}
                phoneNumberRef={phoneNumberInputRef}
                isPhoneNumberValid={isPhoneNumberValid}
                isError={
                  isError &&
                  isError.phoneNumber.error &&
                  isError.phoneNumber.errorMessage
                }
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={12} className="mt-3 pt-2">
              <div style={{ paddingBottom: '5px' }}>
                <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Business Type" />
                </p>
              </div>
              <ReusableTabs
                tabs={tabData}
                defaultTab={1}
                currentTab={(val) => handleTabChange(val)}
                hasBorder={'#DDDDDD'}
              />
            </Col>

            <Col xs={12} md={12} className="mt-4 pt-1">
              <ReusableButton
                fullWidth={true}
                variant="contained"
                color="#F5F5F5"
                fontSize="18px"
                fontWeight="400"
                padding="12px"
                bgColor="#292D32"
                fontFamily="Akkurat"
                endIcon={
                  inviteSendLoader && (
                    <CircularProgress
                      style={{
                        color: 'white',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )
                }
                onClick={() => handleSubmit()}
              >
                <FormattedMessage id="Submit" />
              </ReusableButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GetStarted;
