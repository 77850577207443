import { putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE';

// Action Creators
export const fetchUpdateService = () => ({
  type: UPDATE_SERVICE,
});

export const fetchUpdateServiceSuccess = (data) => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: data,
});

export const fetchUpdateServiceFailure = (error) => ({
  type: UPDATE_SERVICE_FAILURE,
  payload: error,
});

// Thunk Function
export const updateServiceCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchUpdateService(data, external));
    try {
      const userRole = getUserRole();
      const response = await putMethodWithToken(
        userRole === 'super-admin'
          ? url.updateServiceListSuperAdmin +
              external?.id +
              '/' +
              'update' +
              '/'
          : url.updateSubCategoryServiceProviderAdmin + external?.id + '/',

        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchUpdateServiceSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchUpdateServiceFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchUpdateServiceFailure(error.message));
    }
  };
};
