import { deleteMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const DELETE_PROVIDER_SUPERADMIN = 'DELETE_PROVIDER_SUPERADMIN';
export const DELETE_PROVIDER_SUPERADMIN_SUCCESS =
  'DELETE_PROVIDER_SUPERADMIN_SUCCESS';
export const DELETE_PROVIDER_SUPERADMIN_FAILURE =
  'DELETE_PROVIDER_SUPERADMIN_FAILURE';

// Action Creators
export const fetchDeleteProviderSuperAdmin = () => ({
  type: DELETE_PROVIDER_SUPERADMIN,
});

export const fetchDeleteProviderSuperAdminSuccess = (data) => ({
  type: DELETE_PROVIDER_SUPERADMIN_SUCCESS,
  payload: data,
});

export const fetchDeleteProviderSuperAdminFailure = (error) => ({
  type: DELETE_PROVIDER_SUPERADMIN_FAILURE,
  payload: error,
});

// Thunk Function
export const deleteProviderSuperAdminCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchDeleteProviderSuperAdmin(data, external));
    try {
      const response = await deleteMethodWithToken(
        url.deleteProviderListSuperAdmin + external?.id + '/'
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchDeleteProviderSuperAdminSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchDeleteProviderSuperAdminFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchDeleteProviderSuperAdminFailure(error.message));
    }
  };
};
