import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { getUserRole } from 'utils/general';

// Action Types
export const GET_DASHBOARD_PROVIDER_DATA = 'GET_DASHBOARD_PROVIDER_DATA';
export const GET_DASHBOARD_PROVIDER_DATA_SUCCESS =
  'GET_DASHBOARD_PROVIDER_DATA_SUCCESS';
export const GET_DASHBOARD_PROVIDER_DATA_FAILURE =
  'GET_DASHBOARD_PROVIDER_DATA_FAILURE';

// Action Creators
export const fetchGetDashboardProviderData = () => ({
  type: GET_DASHBOARD_PROVIDER_DATA,
});

export const fetchGetDashboardProviderDataSuccess = (data) => ({
  type: GET_DASHBOARD_PROVIDER_DATA_SUCCESS,
  payload: data,
});

export const fetchGetDashboardProviderDataFailure = (error) => ({
  type: GET_DASHBOARD_PROVIDER_DATA_FAILURE,
  payload: error,
});

const getRole = (userRole) => {
  switch (userRole) {
    case 'company-admin':
      return url.getDashboardDataProviderAdmin;
    case 'salon-admin':
      return url.getDashboardDataSalonProviderAdmin;
    case 'branch-company':
      return url.getDashboardDataSalonProviderAdmin;
    case 'specialist-admin':
      return url.getSpecialistDashboardProviderAdmin;
  }
};

// Thunk Function
export const getDashboardProviderDataCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetDashboardProviderData(data, external));
    try {
      const userRole = getUserRole();
      const response = await getMethodWithToken(getRole(userRole));
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetDashboardProviderDataSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetDashboardProviderDataFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetDashboardProviderDataFailure(error.message));
    }
  };
};
