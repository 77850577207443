import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const GET_APPOINTMENTS_LIST = 'GET_APPOINTMENTS_LIST';
export const GET_APPOINTMENTS_LIST_SUCCESS = 'GET_APPOINTMENTS_LIST_SUCCESS';
export const GET_APPOINTMENTS_LIST_FAILURE = 'GET_APPOINTMENTS_LIST_FAILURE';

// Action Creators
export const fetchGetAppointmentsList = () => ({
  type: GET_APPOINTMENTS_LIST,
});

export const fetchGetAppointmentsListSuccess = (data) => ({
  type: GET_APPOINTMENTS_LIST_SUCCESS,
  payload: data,
});

export const fetchGetAppointmentsListFailure = (error) => ({
  type: GET_APPOINTMENTS_LIST_FAILURE,
  payload: error,
});

const getRole = (userRole) => {
  switch (userRole) {
    case 'company-admin':
      return url.getAppointmentListSalonProviderAdmin;
    case 'salon-admin':
      return url.getAppointmentListSalonProviderAdmin;
    case 'branch-company':
      return url.getAppointmentListSalonProviderAdmin;
    case 'specialist-admin':
      return url.getAppointmentListSpecialistProviderAdmin;
  }
};

// Thunk Function
export const getAppointmentsListCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetAppointmentsList(data, external));
    try {
      const userRole = getUserRole();
      const response = await getMethodWithToken(getRole(userRole), data);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAppointmentsListSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetAppointmentsListFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAppointmentsListFailure(error.message));
    }
  };
};
