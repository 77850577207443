import React, { useContext, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import 'assets/styles/common.scss';
import CheckValidation from 'utils/Validation';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import {
  providerAdminDashboard,
  providerOnboarding,
  superAdminDashboard,
} from 'routes/AppUrls';
import { FormattedMessage, useIntl } from 'react-intl';
import LoginLogo from 'assets/images/login/Logo.svg';
import ReusableButton from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { authLoginCallAPI } from 'store/login/loginAction';
import { getUserRole } from 'utils/general';
import { userMeCallAPI } from 'store/UserMeDetails/action';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isError, setIsError] = useState(false);

  const loginLoader = useSelector((state) => state?.loginReducer?.loading);

  const [formValue, setformValue] = useState({
    email: '',
    password: '',
  });

  const handleForgot = () => {
    // navigate(reset(locale));
  };

  const handleSubmit = () => {
    const error = CheckValidation(formValue);
    setIsError(error);
    if (error.submit) {
      let body = {
        username: formValue?.email,
        password: formValue?.password,
        login_method: 'by_email',
      };
      dispatch(
        authLoginCallAPI(body, {
          run: true,
          handleFunction: (res) => handleRedirect(res),
          handleToastError: (res) => ToastErrorFunction(res),
          navigate: navigate,
          toast: true,
        })
      );
    }
  };
  const getUserMeApi = () => {
    dispatch(
      userMeCallAPI(
        {},
        {
          run: true,
          handleFunction: (res) => handleUserMeSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleUserMeSuccess = (res) => {
    if (getUserRole() === 'super-admin') {
      navigate(superAdminDashboard(locale));
    } else {
      if (res?.data?.data?.provider_details?.address) {
        navigate(providerAdminDashboard(locale));
      } else {
        navigate(providerOnboarding(locale));
      }
    }
  };

  const RoleBaseNavigate = () => {
    getUserMeApi();
  };

  const ToastErrorFunction = (res) => {
    setConf({
      msg: intl.formatMessage({ id: 'Invalid Credential!' }),
      variant: 'error',
    });
  };

  const handleRedirect = (res) => {
    if (res?.data?.status_code === 200 && res?.data?.data !== null) {
      setConf({
        msg: intl.formatMessage({ id: 'login successfully!' }),
        variant: 'success',
      });
      RoleBaseNavigate();
    } else {
      setConf({
        msg: intl.formatMessage({
          id: 'Invalid credentials.Please try again.',
        }),
        variant: 'error',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Container fluid>
      <Row
        className="bc-F2F3F6 d-flex justify-center items-center"
        style={{ height: '100vh' }}
      >
        <Col xs={12} md={12} lg={6} xxl={5} className="m-auto">
          <Row className="p-60 m-2 d-flex justify-center items-center bc-FFFFFF">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="fc-2C2C2C fs-32 fw-400 ff-Akkurat_Bold p-0 m-0">
                  <FormattedMessage id="Login" />
                </h2>
                <p className="fc-6B788E fs-16 fw-400 ff-Akkurat p-0 m-0 mt-2 pt-1">
                  <FormattedMessage id="Please enter your details to sign in" />{' '}
                </p>
              </div>
              <img src={LoginLogo} alt="logo" />
            </div>

            <Col xs={12} md={12} className="mt-4 pt-3">
              <LabelWithInput
                label={<FormattedMessage id="E-mail Address" />}
                placeholder={intl.formatMessage({ id: 'E-mail Address' })}
                name="email"
                type="text"
                value={formValue?.email}
                onChange={handleInputChange}
                errorText={
                  isError && isError.email.error && isError.email.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Password" />}
                placeholder={intl.formatMessage({ id: 'Password' })}
                name="password"
                value={formValue?.password}
                type={isPassVisible ? 'text' : 'password'}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <>
                      {!isPassVisible ? (
                        <VisibilityOutlinedIcon
                          sx={{ cursor: 'pointer', color: '#ABABAB' }}
                          onClick={() => setIsPassVisible(!isPassVisible)}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{ cursor: 'pointer', color: '#ABABAB' }}
                          onClick={() => setIsPassVisible(!isPassVisible)}
                        />
                      )}
                    </>
                  ),
                }}
                errorText={
                  isError &&
                  isError.password.error &&
                  isError.password.errorMessage
                }
              />
            </Col>

            <Col
              xs={12}
              md={12}
              className="d-flex justify-content-end mt-3 pt-1"
            >
              <p
                className="fc-292D32 fs-20 fw-400 m-0 p-0 ff-Akkurat underline underline-offset-8"
                onClick={handleForgot}
              >
                <FormattedMessage id="Forgot password ?" />
              </p>
            </Col>

            <Col xs={12} md={12} className="mt-4 pt-1">
              <ReusableButton
                fullWidth={true}
                variant="contained"
                color="#F5F5F5"
                fontSize="18px"
                fontWeight="400"
                padding="12px"
                bgColor="#292D32"
                fontFamily="Akkurat"
                endIcon={
                  loginLoader && (
                    <CircularProgress
                      style={{
                        color: 'white',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )
                }
                onClick={() => handleSubmit()}
              >
                <FormattedMessage id="Login" />
              </ReusableButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
