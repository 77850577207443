import React, { useContext, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import 'assets/styles/common.scss';
import CheckValidation from 'utils/Validation';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import LoginLogo from 'assets/images/login/Logo.svg';
import ReusableButton from 'components/common/Button';
import { createPasswordCallAPI } from 'store/ProviderAdmin/createPassword/action';
import { useDispatch, useSelector } from 'react-redux';
import { tokenVerifyCallAPI } from 'store/ProviderAdmin/tokenVerify/action';
import { getLandingURL } from 'routes/AppUrls';
import CircularProgress from '@mui/material/CircularProgress';

const CreatePassword = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const location = useLocation();
  const [conf, setConf] = ToastNotification();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);
  const [isError, setIsError] = useState(false);

  const params = new URLSearchParams(location.search);
  const ProviderToken = params.get('token');
  const createPasswordLoader = useSelector(
    (state) => state?.createPasswordReducer?.loading
  );

  const [formValue, setformValue] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleSubmit = () => {
    const error = CheckValidation(formValue);
    setIsError(error);
    if (error.submit) {
      handleVerifyToken();
    }
  };

  const handleCreatePassword = (data) => {
    let body = {
      password: formValue?.password,
      provider_id: data?.provider_id,
    };
    dispatch(
      createPasswordCallAPI(body, {
        run: true,
        toast: true,
        handleFunction: (res) => handleRedirect(res),
        handleToastError: (res) => handleError(res),
        navigate: navigate,
      })
    );
  };

  const handleVerifyToken = () => {
    let body = { provider_token: ProviderToken };
    dispatch(
      tokenVerifyCallAPI(body, {
        run: true,
        toast: true,
        handleFunction: (res) => handleSuccess(res),
        handleToastError: (res) => handleError(res),
        navigate: navigate,
      })
    );
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    handleCreatePassword(res?.data?.data);
  };

  const handleError = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setTimeout(() => {
      navigate(getLandingURL(locale));
    }, 1000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Container fluid>
      <Row
        className="bc-F2F3F6 d-flex justify-center items-center"
        style={{ height: '100vh' }}
      >
        <Col xs={12} md={12} lg={6} xxl={5} className="m-auto">
          <Row className="p-60 m-2 d-flex justify-center items-center bc-FFFFFF">
            <div className="flex justify-center items-center">
              <img src={LoginLogo} alt="logo" />
            </div>

            <div className="mt-4">
              <h2 className="fc-2C2C2C fs-32 fw-400 ff-Akkurat_Bold p-0 m-0">
                <FormattedMessage id="Create Password" />
              </h2>
              <p className="fc-6B788E fs-16 fw-400 ff-Akkurat p-0 m-0 mt-1">
                <FormattedMessage id="Rutrum arcu mauris diam ultrices id aliquet turpis." />
              </p>
            </div>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Password" />}
                placeholder={intl.formatMessage({ id: 'Password' })}
                name="password"
                value={formValue?.password}
                type={isPassVisible ? 'text' : 'password'}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <>
                      {!isPassVisible ? (
                        <VisibilityOutlinedIcon
                          sx={{ cursor: 'pointer', color: '#ABABAB' }}
                          onClick={() => setIsPassVisible(!isPassVisible)}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{ cursor: 'pointer', color: '#ABABAB' }}
                          onClick={() => setIsPassVisible(!isPassVisible)}
                        />
                      )}
                    </>
                  ),
                }}
                errorText={
                  isError &&
                  isError.password.error &&
                  isError.password.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Confirm Password" />}
                placeholder={intl.formatMessage({ id: 'Confirm Password' })}
                name="confirmPassword"
                value={formValue?.confirmPassword}
                type={isConfirmPassVisible ? 'text' : 'password'}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <>
                      {!isConfirmPassVisible ? (
                        <VisibilityOutlinedIcon
                          sx={{ cursor: 'pointer', color: '#ABABAB' }}
                          onClick={() =>
                            setIsConfirmPassVisible(!isConfirmPassVisible)
                          }
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{ cursor: 'pointer', color: '#ABABAB' }}
                          onClick={() =>
                            setIsConfirmPassVisible(!isConfirmPassVisible)
                          }
                        />
                      )}
                    </>
                  ),
                }}
                errorText={
                  isError &&
                  isError.confirmPassword.error &&
                  isError.confirmPassword.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={12} className="mt-4 pt-1">
              <ReusableButton
                fullWidth={true}
                variant="contained"
                color="#F5F5F5"
                fontSize="18px"
                fontWeight="400"
                padding="12px"
                bgColor="#292D32"
                fontFamily="Akkurat"
                endIcon={
                  createPasswordLoader && (
                    <CircularProgress
                      style={{
                        color: 'white',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )
                }
                onClick={() => handleSubmit()}
              >
                <FormattedMessage id="Submit" />
              </ReusableButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePassword;
