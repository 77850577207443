import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Table, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import Customer from 'assets/images/sidebar/Customer.png';
import Appointment from 'assets/images/sidebar/AppointmentColor.png';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import Avtar from 'assets/images/Dashboard/avtar.png';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import Specialist from 'assets/images/Dashboard/Specialist.png';
import ReusableTabs from 'components/common/Tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import SwitchInput from 'components/common/Input/SwitchInput';
import { getDashboardDataCallAPI } from 'store/SuperAdmin/dashboard/action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ToastNotification from 'components/common/Toast';
import { deleteCustomerSuperAdminCallAPI } from 'store/SuperAdmin/deleteCustomer/action';
import { deleteProviderSuperAdminCallAPI } from 'store/SuperAdmin/deleteProvider/action';
import {
  superAdminAppointmentsDetails,
  superAdminCompanyProviderDetails,
  superAdminCustomerDetails,
  superAdminSalonProviderDetails,
  superAdminSpecialistProviderDetails,
} from 'routes/AppUrls';
import {
  basicDetailsCallApi,
  DASHBOARD_NEW_USER_SELECTED_TAB,
} from 'store/SuperAdmin/basicDetails/action';
import { useConfirm } from 'material-ui-confirm';
import Lottie from 'lottie-react';
import animationData from 'assets/Lottie/no_data.json';

const Dashboard = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [conf, setConf] = ToastNotification();
  const { locale } = useContext(LocaleContext);

  const getNewUserSelectedTab = useSelector(
    (state) => state?.basicDetailsReducer?.dashboardNewUserSelectedTab
  );
  const [tab, setTab] = useState(getNewUserSelectedTab);

  console.log(tab);
  const [checked, setChecked] = useState(false);

  const getDashboardDetails = useSelector(
    (state) => state?.getDashboardDataReducer?.data?.data
  );

  const [dashboardDetails, setDashboardDetails] = useState(
    getDashboardDetails ? getDashboardDetails : ''
  );

  const tabData = [
    { label: intl.formatMessage({ id: 'Customers' }) },
    { label: intl.formatMessage({ id: 'Specialists' }) },
    { label: intl.formatMessage({ id: 'Salons' }) },
    { label: intl.formatMessage({ id: 'Companies' }) },
  ];

  useEffect(() => {
    // getDashboardApi();
    handleTabChange(tab);
  }, []);

  const getDashboardApi = (val = '') => {
    dispatch(
      getDashboardDataCallAPI(
        {},
        {
          type: val,
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setDashboardDetails(res?.data?.data);
  };

  const handleTabChange = (val) => {
    setTab(val);
    dispatch(basicDetailsCallApi(DASHBOARD_NEW_USER_SELECTED_TAB, val));
    if (val === 1) {
      getDashboardApi('');
    } else if (val === 2) {
      getDashboardApi('specialist');
    } else if (val === 3) {
      getDashboardApi('salon');
    } else if (val === 4) {
      getDashboardApi('company');
    }
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const handleDeleteCustomer = (key) => {
    confirm({
      title: 'Confirm Delete',
      description: 'Are you sure you want to delete Customer ?',
      confirmationText: 'Yes',
      cancellationText: 'No',
      dialogProps: { maxWidth: 'xs' },
      confirmationButtonProps: {
        sx: {
          bgcolor: '#292D32',
          color: '#FFFFFF',
          borderRadius: '0px',
          border: '1px solid #292D32',
          '&:hover': { bgcolor: '#292D32' },
        },
      },
      cancellationButtonProps: {
        sx: {
          bgcolor: 'transparent',
          color: '#292D32',
          borderRadius: '0px',
          border: '1px solid #292D32',
        },
      },
    })
      .then(() => handleConfirmDeleteCustomer(key))
      .catch(() => console.log('Deletion cancelled.'));
  };

  const handleConfirmDeleteCustomer = (key) => {
    if (tab === 1) {
      dispatch(
        deleteCustomerSuperAdminCallAPI(
          {},
          {
            id: key?.id,
            run: true,
            handleFunction: (res) => handleDeleteSuccess(res),
            navigate: navigate,
          }
        )
      );
    } else {
      dispatch(
        deleteProviderSuperAdminCallAPI(
          {},
          {
            id: key?.id,
            run: true,
            handleFunction: (res) => handleDeleteSuccess(res),
            navigate: navigate,
          }
        )
      );
    }
  };

  const handleDeleteSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    handleTabChange(tab);
  };

  const handleAppointmentDetails = (key) => {
    navigate(superAdminAppointmentsDetails(locale), {
      state: { customerId: key?.id },
    });
  };

  const objectMapping = {
    1: superAdminCustomerDetails,
    2: superAdminSpecialistProviderDetails,
    3: superAdminSalonProviderDetails,
    4: superAdminCompanyProviderDetails,
  };

  const handleViewDetails = (data) => {
    const NavigateFunction = objectMapping?.[tab];
    navigate(NavigateFunction(locale), {
      state: tab === 1 ? { customerId: data?.id } : { data },
    });
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Dashboard" />
        </h2>

        <Row className="mt-3 ">
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Provider}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat truncate">
                    <FormattedMessage id="Total Salons" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {dashboardDetails?.count_data?.total_salon}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Specialist}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat truncate">
                    <FormattedMessage id="Total Specialists" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {dashboardDetails?.count_data?.total_specialist}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Customer}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat truncate">
                    <FormattedMessage id="Total Customers" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {dashboardDetails?.count_data?.total_customuser}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Appointment}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat truncate">
                    <FormattedMessage id="Total Appointments" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {dashboardDetails?.count_data?.total_appointment}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4 pt-1">
          <div className="d-flex justify-content-between align-items-center ">
            <h5 className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Appointments Today" />
            </h5>
            {/* <Stack
              direction="horizontal"
              gap={2}
              className="items-center underline underline-offset-4"
            >
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                <FormattedMessage id="View All" />
              </p>
              <img
                src={rightArrow}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
            </Stack> */}
          </div>
        </Row>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Customer" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Provider" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {dashboardDetails?.appoinment_today?.length > 0 ? (
            dashboardDetails.appoinment_today.map((key, index) => (
              <Row className="m-0 p-0 flex items-center listAppointment">
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Avtar}
                      alt="icon"
                      style={{ height: '40px', width: '40px' }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {key?.customers_data?.customers_name}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.customers_data?.customers_id}
                      </p>
                    </div>
                  </Stack>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Avtar}
                      alt="icon"
                      style={{ height: '40px', width: '40px' }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {key?.provider_data?.name_en}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.provider_data?.provider_id}
                      </p>
                    </div>
                  </Stack>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Provider}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {key?.service_location}
                    </p>
                  </Stack>
                  <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1 truncate">
                    {key?.delivery_address}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.booking_id}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {(key?.slot_date !== null ||
                      key?.slot_date !== undefined) &&
                      moment(key?.slot_date).format('DD MMM YYYY')}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={() => handleAppointmentDetails(key)}
                  >
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                      <FormattedMessage id="View Details" />
                    </p>
                    <img
                      src={rightArrow}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                  </Stack>
                </Col>
              </Row>
            ))
          ) : (
            <Row className="bc-FFFFFF m-0 p-50 flex justify-center items-center">
              <Lottie
                animationData={animationData}
                loop={true}
                style={{ height: '200px', width: '200px' }}
              />
            </Row>
          )}
        </Row>

        <Row className="m-0 p-0 mt-5">
          <h2 className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
            <FormattedMessage id="New Users" />
          </h2>
          <Col md={12} lg={12} xl={12} xxl={8} className="m-0 p-0 mt-3">
            <ReusableTabs
              tabs={tabData}
              defaultTab={tab}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
        </Row>

        {/* {tab === 1 && ( */}
        <>
          <Row className="mt-3 listHeader m-0 p-0 flex items-center">
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Specialist" />
              </p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Location" />
              </p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Contact Info" />
              </p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Joined On" />
              </p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Status" />
              </p>
            </Col>
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Actions" />
              </p>
            </Col>
          </Row>

          <Row className="bc-FFFFFF m-0 p-0">
            {dashboardDetails?.new_user_details?.length > 0 ? (
              dashboardDetails.new_user_details.map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {key?.name?.name_en}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {key?.id}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {key?.address}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {key?.email}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {key?.contact_number}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {(key?.joining_on !== null ||
                        key?.joining_on !== undefined) &&
                        moment(key?.joining_on).format('DD MMM YYYY')}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <SwitchInput
                      label={true}
                      checked={key?.status}
                      onChange={(e) => handleSwitchChange(e)}
                      color="secondary"
                    />
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        className="cursor-pointer"
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        onClick={() => handleDeleteCustomer(key)}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={() => handleViewDetails(key)}
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))
            ) : (
              <Row className="bc-FFFFFF m-0 p-50 flex justify-center items-center">
                <Lottie
                  animationData={animationData}
                  loop={true}
                  style={{ height: '200px', width: '200px' }}
                />
              </Row>
            )}
          </Row>
        </>
      </Col>
    </Row>
  );
};

export default Dashboard;
