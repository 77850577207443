import { putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const CANCEL_SPECIALIST_INVITATION = 'CANCEL_SPECIALIST_INVITATION';
export const CANCEL_SPECIALIST_INVITATION_SUCCESS =
  'CANCEL_SPECIALIST_INVITATION_SUCCESS';
export const CANCEL_SPECIALIST_INVITATION_FAILURE =
  'CANCEL_SPECIALIST_INVITATION_FAILURE';

// Action Creators
export const fetchCancelSpecialistInvitation = () => ({
  type: CANCEL_SPECIALIST_INVITATION,
});

export const fetchCancelSpecialistInvitationSuccess = (data) => ({
  type: CANCEL_SPECIALIST_INVITATION_SUCCESS,
  payload: data,
});

export const fetchCancelSpecialistInvitationFailure = (error) => ({
  type: CANCEL_SPECIALIST_INVITATION_FAILURE,
  payload: error,
});

// Thunk Function
export const cancelSpecialistInvitationCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchCancelSpecialistInvitation(data, external));
    try {
      const response = await putMethodWithToken(
        url.cancelSpecialistInvitationProviderAdmin + external?.id + '/',
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchCancelSpecialistInvitationSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchCancelSpecialistInvitationFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchCancelSpecialistInvitationFailure(error.message));
    }
  };
};
