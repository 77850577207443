import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { providerAdminSpecialists, superAdminProvider } from 'routes/AppUrls';
import ReusableTabs from 'components/common/Tabs';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import SwitchInput from 'components/common/Input/SwitchInput';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import visaCardIcon from 'assets/images/provider/visa.png';
import PhotoImage from 'assets/images/provider/image4.png';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import Avtar from 'assets/images/Dashboard/avtar.png';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ScrollableTabs from 'components/common/SrollableTabs';
import ServiceList from 'Pages/SuperAdmin/Categories/ServiceList';
import MiniCalendar from 'Pages/SuperAdmin/MiniCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProviderInformationDetailsCallAPI } from 'store/SuperAdmin/getAllProviderInformation/action';
import moment from 'moment';
import {
  basicDetailsCallApi,
  SELECTED_TAB,
  SPECIALIST_DETAILS_SELECTED_TAB,
} from 'store/SuperAdmin/basicDetails/action';
import { getBasicProviderInformationCallAPI } from 'store/SuperAdmin/basicProviderInformation/action';
import { getUserRole } from 'utils/general';

const SpecialistDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const userRole = getUserRole();
  const { locale } = useContext(LocaleContext);

  const getSpecialistDetailSelectedTab = useSelector(
    (state) => state?.basicDetailsReducer?.spcialistDetailsSelectedTab
  );
  const [tab, setTab] = useState(getSpecialistDetailSelectedTab);
  const specialistImageFileInputRef = useRef();
  const [specialistImageFile, setSpecialistImageFile] = useState(null);
  const [checked, setChecked] = useState(false);

  const getselectedTab = useSelector(
    (state) => state?.basicDetailsReducer?.selectedTab
  );
  const [selectedTab, setSelectedTab] = useState(getselectedTab);
  const [scrollTabData, setScrollTabData] = useState(null);
  const providerAllInformation = useSelector(
    (state) => state?.getAllProviderInformationDetailsReducer?.data?.data
  );
  const basicProviderInformation = useSelector(
    (state) => state?.getBasicProviderInforrmationReducer?.data?.data
  );

  useEffect(() => {
    // AllProviderInformationApi();
    handleTabChange(tab);
    getBasicProviderDetails();
  }, []);

  useEffect(() => {
    const selectedCategory =
      providerAllInformation?.length > 0 && providerAllInformation[selectedTab];
    setScrollTabData(selectedCategory);
    dispatch(basicDetailsCallApi(SELECTED_TAB, selectedTab));
  }, [selectedTab, providerAllInformation]);

  const tabData = [
    { label: intl.formatMessage({ id: 'About' }) },
    { label: intl.formatMessage({ id: 'Services' }) },
    { label: intl.formatMessage({ id: 'Schedule' }) },
    { label: intl.formatMessage({ id: 'Appointment History' }) },
    { label: intl.formatMessage({ id: 'Reviews' }) },
  ];

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title:
        userRole === 'super-admin' ? (
          <FormattedMessage id="Providers" />
        ) : (
          <FormattedMessage id="Specialists" />
        ),
      Link:
        userRole === 'super-admin'
          ? superAdminProvider(locale)
          : providerAdminSpecialists(locale),
      isDisbale: false,
    },
    {
      title:
        userRole === 'super-admin' ? (
          <FormattedMessage id="Provider Details" />
        ) : (
          <FormattedMessage id="Specialist’s Details" />
        ),
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleTabChange = (val) => {
    setTab(val);
    dispatch(basicDetailsCallApi(SPECIALIST_DETAILS_SELECTED_TAB, val));
    switch (val) {
      case 1:
        AllProviderInformationApi('about');
        break;
      case 2:
        AllProviderInformationApi('services');
        break;
      case 3:
        AllProviderInformationApi('schedule');
        break;
      case 4:
        AllProviderInformationApi('appointment_history');
        break;
      case 5:
        AllProviderInformationApi('reviews');
        break;
    }
  };

  const getBasicProviderDetails = () => {
    dispatch(
      getBasicProviderInformationCallAPI(
        {},
        {
          id: state?.data?.id,
          navigate: navigate,
        }
      )
    );
  };

  const AllProviderInformationApi = (type = 'about') => {
    dispatch(
      getAllProviderInformationDetailsCallAPI(type, {
        id: state?.data?.id,
        navigate: navigate,
      })
    );
  };

  const handleScrollTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const handleSpecialistImageFileChange = (e) => {
    const file = e.target.files[0];
    setSpecialistImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSpecialistImageUpload = () => {
    specialistImageFileInputRef.current.click();
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const ImageList = (images, maxVisible) => {
    const visibleImages = images.slice(0, maxVisible);
    const remainingCount = images.length - maxVisible;

    return (
      <div className="flex flex-wrap mt-3 gap-3 p-0">
        {visibleImages.map((image, index) => (
          <div className="image-item relative" key={index}>
            <img
              src={image?.image}
              alt={`Image ${index + 1}`}
              style={{ height: '124px', width: '124px' }}
            />
            {index === maxVisible - 1 && remainingCount > 0 && (
              <div className="overlay">
                <span>+{remainingCount}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <input
            ref={specialistImageFileInputRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleSpecialistImageFileChange}
          />

          <div className="mt-4 flex justify-between items-center">
            <Stack direction="horizontal" gap={4} className="items-center">
              <div className="AvtarImageIcon">
                {basicProviderInformation?.profile_photo && (
                  <img
                    src={
                      basicProviderInformation?.profile_photo !== ''
                        ? basicProviderInformation?.profile_photo?.file_url
                        : specialistImageFile
                    }
                    alt={'icon'}
                    style={{
                      height: '88px',
                      width: '88px',
                      borderRadius: '50%',
                    }}
                  />
                )}
                <img
                  src={ProfileIcon}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="profileIcon"
                  onClick={handleSpecialistImageUpload}
                />
              </div>
              <div>
                <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  {basicProviderInformation?.name?.name_en}
                </p>
              </div>
            </Stack>

            <div className="">
              <SwitchInput
                label={<FormattedMessage id="Test User" />}
                checked={checked}
                onChange={(e) => handleSwitchChange(e)}
                color="secondary"
              />
            </div>
          </div>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={callIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                Phone number
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {basicProviderInformation?.contact_number}
            </p>
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={mailIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">Email</p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {basicProviderInformation?.email}
            </p>
          </Col>

          <Col xs={12} md={4} className="mt-3">
            <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">Address</p>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {basicProviderInformation?.address}
            </p>
          </Col>

          <Col xs={12} md={2} className="mt-3 text-center">
            <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
              Active since:
            </p>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {basicProviderInformation?.joining_on
                ? moment(basicProviderInformation?.joining_on).format(
                    'DD MMM YYYY'
                  )
                : ''}
            </p>
          </Col>
        </Row>

        <Row className="mt-3 pt-1">
          <Col xs={12} md={10}>
            <ReusableTabs
              tabs={tabData}
              defaultTab={tab}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
        </Row>

        {tab === 1 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Information'}
              </h5>
              <p className="p-0 m-0 fs-16 fc-A2A2A2 fw-400 ff-Akkurat mt-3">
                {providerAllInformation?.about_information_en}
              </p>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Payment Methods'}
              </h5>
              <div className="flex flex-wrap mt-3 gap-3  p-0">
                {providerAllInformation?.paymentmethods?.length > 0 &&
                  providerAllInformation.paymentmethods.map((key, index) => {
                    if (key?.name === 'Visa') {
                      return (
                        <img
                          src={visaCardIcon}
                          alt="icon"
                          style={{ height: '40px', width: '73px' }}
                        />
                      );
                    } else {
                      return (
                        <img
                          src={visaCardIcon}
                          alt="icon"
                          style={{ height: '40px', width: '73px' }}
                        />
                      );
                    }
                  })}
              </div>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Photos'}
              </h5>
              {providerAllInformation?.bussiness_images?.length > 0 && (
                <>
                  <div className="p-0">
                    {ImageList(providerAllInformation?.bussiness_images, 10)}
                  </div>
                </>
              )}
            </Row>
          </>
        )}

        {tab === 2 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              {providerAllInformation?.length > 0 && (
                <>
                  <Row className="m-0 p-0">
                    <Col md={12}>
                      <ScrollableTabs
                        tabs={providerAllInformation.map((items, index) => ({
                          label: items?.category_name_en,
                          icon: items?.category_icon,
                        }))}
                        onTabChange={handleScrollTabChange}
                        initialTab={0}
                      />
                    </Col>
                  </Row>

                  {providerAllInformation.map(
                    (items, index) =>
                      scrollTabData?.category_id === items?.category_id && (
                        <>
                          {items?.products_list?.length > 0 && (
                            <Row className="m-0 p-0 mt-2">
                              <ServiceList
                                services={items?.products_list}
                                // onEdit={(data) => handleEditService(data)}
                                // onDelete={(data) => handleDeleteService(data)}
                                // onDeleteSubService={(data) =>
                                //   handleDeleteSubService(data)
                                // }
                                // onAddService={(data) => handleAddService(data)}
                              />
                            </Row>
                          )}
                        </>
                      )
                  )}
                </>
              )}
            </Row>
          </>
        )}

        {tab === 3 && (
          <>
            <Row className="m-0 p-0 mt-3">
              {/* <MiniCalendar eventsData={providerAllInformation} /> */}
              <MiniCalendar />
            </Row>
          </>
        )}

        {tab === 4 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Customer" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Service Location" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Booking ID" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Date & Time" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {providerAllInformation?.length > 0 &&
                providerAllInformation.map((key, index) => (
                  <Row className="m-0 p-0 flex items-center listAppointment">
                    <Col lg={3} md={6} sm={12}>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center"
                      >
                        <img
                          src={Avtar}
                          alt="icon"
                          style={{ height: '40px', width: '40px' }}
                        />
                        <div>
                          <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {key?.customers_data?.customers_name}
                          </p>
                          <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                            {key?.customers_data?.customers_id}
                          </p>
                        </div>
                      </Stack>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center"
                      >
                        <img
                          src={Provider}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {key?.service_location}
                        </p>
                      </Stack>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1 truncate">
                        {key?.delivery_address}
                      </p>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.booking_id}
                      </p>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.slot_date !== null && key?.slot_date !== undefined
                          ? moment(key?.slot_date).format('DD MMM YYYY')
                          : ''}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.slot_start_time !== null &&
                        key?.slot_start_time !== undefined
                          ? moment(key?.slot_start_time).format('hh:mm A')
                          : ''}
                      </p>
                    </Col>
                  </Row>
                ))}
            </Row>
          </>
        )}

        {tab === 5 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <Col xs={12} md={12}>
                <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
                  {'Reviews'}
                </h5>
              </Col>

              <Col xs={12} md={3} className="mt-3">
                <p className="p-0 m-0 fs-32 fw-400 fc-292D32 ff-Akkurat_Bold">
                  {providerAllInformation?.average_rating}
                </p>
                <Stack
                  direction="horizontal"
                  spacing={1}
                  className="mt-1 items-center"
                >
                  {[1, 2, 3, 4, 5].map((star) => (
                    <div
                      key={star}
                      className={
                        star <= providerAllInformation?.average_rating
                          ? 'starfill'
                          : 'starUnfill'
                      }
                    >
                      {star <= providerAllInformation?.average_rating ? (
                        <StarIcon fontSize="medium" />
                      ) : (
                        <StarBorderIcon fontSize="medium" />
                      )}
                    </div>
                  ))}
                </Stack>
              </Col>

              <Col xs={12} md={7} className="">
                {providerAllInformation?.reviews?.length > 0 &&
                  providerAllInformation.reviews.map((value, index) => (
                    <Col
                      xs={12}
                      md={12}
                      className="mt-3 border-b bColor-E3E3E3 pb-2"
                    >
                      <Stack
                        direction="horizontal"
                        gap={3}
                        className="items-center"
                      >
                        <img
                          src={
                            value?.given_by_user?.profile_image !== null &&
                            value?.given_by_user?.profile_image !== undefined
                              ? value?.given_by_user?.profile_image
                              : PhotoImage
                          }
                          alt={'icon'}
                          style={{
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%',
                          }}
                        />
                        <div>
                          <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {value?.given_by_user?.name_en}
                          </p>
                          <p className="p-0 m-0 mt-2 fs-14 fc-A2A2A2 fw-400 ff-Akkurat">
                            {/* {"22nd March, 2024"} */}
                          </p>
                        </div>
                      </Stack>

                      <Stack
                        direction="horizontal"
                        gap={3}
                        className="mt-3 items-center"
                      >
                        <>
                          <div className="flex">
                            {[1, 2, 3, 4, 5].map((star) => (
                              <div
                                key={star}
                                className={
                                  star <= value?.rating
                                    ? 'starfill'
                                    : 'starUnfill'
                                }
                              >
                                {star <= value?.rating ? (
                                  <StarIcon fontSize="medium" />
                                ) : (
                                  <StarBorderIcon fontSize="medium" />
                                )}
                              </div>
                            ))}
                          </div>
                          <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {value?.rating}
                          </p>
                        </>
                      </Stack>

                      <div className="mt-2">
                        <p className="p-0 m-0 fs-16 fc-A2A2A2 fw-400 ff-Akkurat">
                          {value?.review}
                        </p>
                      </div>
                    </Col>
                  ))}
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default SpecialistDetails;
