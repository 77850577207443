import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';

// Action Types
export const GET_APPOINTMENT_DAYS_LIST = 'GET_APPOINTMENT_DAYS_LIST';
export const GET_APPOINTMENT_DAYS_LIST_SUCCESS =
  'GET_APPOINTMENT_DAYS_LIST_SUCCESS';
export const GET_APPOINTMENT_DAYS_LIST_FAILURE =
  'GET_APPOINTMENT_DAYS_LIST_FAILURE';

// Action Creators
export const fetchGetAppointmentDays = () => ({
  type: GET_APPOINTMENT_DAYS_LIST,
});

export const fetchGetAppointmentDaysSuccess = (data) => ({
  type: GET_APPOINTMENT_DAYS_LIST_SUCCESS,
  payload: data,
});

export const fetchGetAppointmentDaysFailure = (error) => ({
  type: GET_APPOINTMENT_DAYS_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getAppointmentDaysProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetAppointmentDays(data, external));
    try {
      const response = await getMethodWithToken(url.getAppointmentDaysList);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAppointmentDaysSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetAppointmentDaysFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAppointmentDaysFailure(error.message));
    }
  };
};
