import { putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const ON_HOLD_PROVIDER = 'ON_HOLD_PROVIDER';
export const ON_HOLD_PROVIDER_SUCCESS = 'ON_HOLD_PROVIDER_SUCCESS';
export const ON_HOLD_PROVIDER_FAILURE = 'ON_HOLD_PROVIDER_FAILURE';

// Action Creators
export const fetchOnHoldProvider = () => ({
  type: ON_HOLD_PROVIDER,
});

export const fetchOnHoldProviderSuccess = (data) => ({
  type: ON_HOLD_PROVIDER_SUCCESS,
  payload: data,
});

export const fetchOnHoldProviderFailure = (error) => ({
  type: ON_HOLD_PROVIDER_FAILURE,
  payload: error,
});

// Thunk Function
export const onHoldProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchOnHoldProvider(data, external));
    try {
      const response = await putMethodWithToken(
        url.OnHoldProviderRequestSuperAdmin + data?.data?.id + '/'
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchOnHoldProviderSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchOnHoldProviderFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchOnHoldProviderFailure(error.message));
    }
  };
};
