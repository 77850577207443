import {
  GET_APPOINTMENT_DAYS_LIST,
  GET_APPOINTMENT_DAYS_LIST_FAILURE,
  GET_APPOINTMENT_DAYS_LIST_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const getAppointmentDaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_DAYS_LIST:
      return { ...state, loading: true };
    case GET_APPOINTMENT_DAYS_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case GET_APPOINTMENT_DAYS_LIST_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default getAppointmentDaysReducer;
