import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';

// Action Types
export const GET_APPOINTMENT_LIST = 'GET_APPOINTMENT_LIST';
export const GET_APPOINTMENT_LIST_SUCCESS = 'GET_APPOINTMENT_LIST_SUCCESS';
export const GET_APPOINTMENT_LIST_FAILURE = 'GET_APPOINTMENT_LIST_FAILURE';

// Action Creators
export const fetchGetAppointment = () => ({
  type: GET_APPOINTMENT_LIST,
});

export const fetchGetAppointmentSuccess = (data) => ({
  type: GET_APPOINTMENT_LIST_SUCCESS,
  payload: data,
});

export const fetchGetAppointmentFailure = (error) => ({
  type: GET_APPOINTMENT_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getAppointmentCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetAppointment(data, external));
    try {
      const response = await getMethodWithToken(
        url.getAppointmentDataSuperAdmin +
          '?page_size=' +
          data?.pageSize +
          '&page=' +
          data?.page
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAppointmentSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetAppointmentFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAppointmentFailure(error.message));
    }
  };
};
