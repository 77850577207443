import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import 'components/sidebar/sidebar.scss';
import 'assets/styles/common.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import {
  getLandingURL,
  providerAdminAppointments,
  providerAdminBranches,
  providerAdminCategory,
  providerAdminCustomer,
  providerAdminDashboard,
  providerAdminProfile,
  providerAdminSpecialists,
  superAdminAppointments,
  superAdminCampaigns,
  superAdminCategory,
  superAdminCustomer,
  superAdminDashboard,
  superAdminProvider,
} from 'routes/AppUrls';
import { FormattedMessage, useIntl } from 'react-intl';
import Dashboard from 'assets/images/sidebar/dashboard.png';
import DashboardColor from 'assets/images/sidebar/dashboardColor.png';
import Provider from 'assets/images/sidebar/Provider.png';
import ProviderColor from 'assets/images/sidebar/ProviderColor.png';
import Customer from 'assets/images/sidebar/Customer.png';
import CustomerColor from 'assets/images/sidebar/CustomerColor.png';
import Profile from 'assets/images/sidebar/Profile.png';
import ProfileColor from 'assets/images/sidebar/ProfileColor.png';
import Service from 'assets/images/sidebar/Service.png';
import ServiceColor from 'assets/images/sidebar/ServiceColor.png';
import Appointment from 'assets/images/sidebar/Appointment.png';
import AppointmentColor from 'assets/images/sidebar/AppointmentColor.png';
import Branches from 'assets/images/sidebar/Branches.png';
import BranchesColor from 'assets/images/sidebar/BranchesColor.png';
import Specialist from 'assets/images/sidebar/Specialist.png';
import SpecialistColor from 'assets/images/sidebar/SpecialistColor.png';
import Campaign from 'assets/images/sidebar/Campaign.png';
import CampaignColor from 'assets/images/sidebar/CampaignColor.png';
import Logout from 'assets/images/sidebar/logout.png';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';
import MenuIcon from '@mui/icons-material/Menu';
import sidebarLogo from 'assets/images/sidebar/sidebarLogo.png';
import {
  ACTIVE_INDEX,
  basicDetailsCallApi,
} from 'store/SuperAdmin/basicDetails/action';
import { useConfirm } from 'material-ui-confirm';

const Sidebar = (props) => {
  const { window } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [conf, setConf] = ToastNotification();
  const confirm = useConfirm();
  const userRole = getUserRole(); // Get the user's role

  const aIndex = useSelector(
    (state) => state?.basicDetailsReducer?.activeIndex
  );

  const [activeIndex, setActiveIndex] = useState(aIndex >= 0 ? aIndex : 0);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  const sidebarData = [
    {
      name: intl.formatMessage({ id: 'Dashboard' }),
      icon: Dashboard,
      selectedIcon: DashboardColor,
      roles: [
        'super-admin',
        'salon-admin',
        'specialist-admin',
        'company-admin',
        'branch-company',
      ],
    },
    {
      name: intl.formatMessage({ id: 'Appointments' }),
      icon: AppointmentColor,
      selectedIcon: Appointment,
      roles: [
        'salon-admin',
        'specialist-admin',
        'company-admin',
        'branch-company',
      ],
    },
    {
      name: intl.formatMessage({ id: 'Branches' }),
      icon: Branches,
      selectedIcon: BranchesColor,
      roles: ['company-admin'],
    },
    {
      name: intl.formatMessage({ id: 'Specialists' }),
      icon: Specialist,
      selectedIcon: SpecialistColor,
      roles: ['salon-admin', 'company-admin', 'branch-company'],
    },
    {
      name: intl.formatMessage({ id: 'Customers' }),
      icon: Customer,
      selectedIcon: CustomerColor,
      roles: [
        'salon-admin',
        'specialist-admin',
        'company-admin',
        'branch-company',
      ],
    },
    {
      name: intl.formatMessage({ id: 'Service' }),
      icon: ServiceColor,
      selectedIcon: Service,
      roles: [
        'salon-admin',
        'specialist-admin',
        'company-admin',
        'branch-company',
      ],
    },
    {
      name: intl.formatMessage({ id: 'Profile' }),
      icon: ProfileColor,
      selectedIcon: Profile,
      roles: [
        'salon-admin',
        'specialist-admin',
        'company-admin',
        'branch-company',
      ],
    },
    {
      name: intl.formatMessage({ id: 'Providers' }),
      icon: ProviderColor,
      selectedIcon: Provider,
      roles: ['super-admin'],
    },
    {
      name: intl.formatMessage({ id: 'Customers' }),
      icon: Customer,
      selectedIcon: CustomerColor,
      roles: ['super-admin'],
    },
    {
      name: intl.formatMessage({ id: 'Service Categories' }),
      icon: ServiceColor,
      selectedIcon: Service,
      roles: ['super-admin'],
    },
    {
      name: intl.formatMessage({ id: 'Appointments' }),
      icon: AppointmentColor,
      selectedIcon: Appointment,
      roles: ['super-admin'],
    },
    {
      name: intl.formatMessage({ id: 'Campaigns' }),
      icon: CampaignColor,
      selectedIcon: Campaign,
      roles: ['super-admin'],
    },
    {
      name: intl.formatMessage({ id: 'Logout' }),
      icon: Logout,
      selectedIcon: Logout,
      roles: [
        'super-admin',
        'salon-admin',
        'specialist-admin',
        'company-admin',
        'branch-company',
      ],
    },
  ];

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMenuItemClick = (text, index) => {
    setOpenSubmenuIndex(null);
    switch (text?.name) {
      case intl.formatMessage({ id: 'Dashboard' }):
        userRole === 'super-admin'
          ? navigate(superAdminDashboard(locale))
          : navigate(providerAdminDashboard(locale));
        break;
      case intl.formatMessage({ id: 'Providers' }):
        navigate(superAdminProvider(locale));
        break;
      case intl.formatMessage({ id: 'Customers' }):
        userRole === 'super-admin'
          ? navigate(superAdminCustomer(locale))
          : navigate(providerAdminCustomer(locale));
        break;
      case intl.formatMessage({ id: 'Service Categories' }):
        navigate(superAdminCategory(locale));
        break;
      case intl.formatMessage({ id: 'Service' }):
        navigate(providerAdminCategory(locale));
        break;
      case intl.formatMessage({ id: 'Profile' }):
        navigate(providerAdminProfile(locale));
        break;
      case intl.formatMessage({ id: 'Appointments' }):
        userRole === 'super-admin'
          ? navigate(superAdminAppointments(locale))
          : navigate(providerAdminAppointments(locale));
        break;
      case intl.formatMessage({ id: 'Branches' }):
        navigate(providerAdminBranches(locale));
        break;
      case intl.formatMessage({ id: 'Specialists' }):
        navigate(providerAdminSpecialists(locale));
        break;
      case intl.formatMessage({ id: 'Campaigns' }):
        navigate(superAdminCampaigns(locale));
        break;
      case intl.formatMessage({ id: 'Logout' }):
        navigate(handleLogOut());
        break;
    }
    setActiveIndex(index);
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
    dispatch(basicDetailsCallApi(ACTIVE_INDEX, index));
  };

  const handleLogOut = () => {
    // ClearTokenRedirectLogin(navigate);
    confirm({
      title: intl.formatMessage({ id: 'Confirm Logout' }),
      description: intl.formatMessage({
        id: 'Are you sure you want to Logout?',
      }),
      confirmationText: intl.formatMessage({ id: 'Logout' }),
      cancellationText: intl.formatMessage({ id: 'Cancel' }),
      dialogProps: { maxWidth: 'xs' },
      confirmationButtonProps: {
        sx: {
          bgcolor: '#292D32',
          color: '#FFFFFF',
          borderRadius: '0px',
          '&:hover': { bgcolor: '#292D32' },
        },
      },
      cancellationButtonProps: {
        sx: {
          bgcolor: 'transparent',
          color: '#292D32',
          borderRadius: '0px',
          border: '1px solid #292D32',
        },
      },
    })
      .then(() => ClearTokenRedirectLogin(navigate))
      .catch(() => console.log('Deletion cancelled.'));
  };

  const handleRedirect = (res) => {
    if (res?.data?.status_code === 200) {
      setConf({
        msg: intl.formatMessage({ id: 'logoutsuccessfully!' }),
        variant: 'success',
      });
      navigate(getLandingURL(locale));
    } else {
      setConf({
        msg: intl.formatMessage({ id: 'Pleasetryagain.' }),
        variant: 'error',
      });
    }
  };

  const ToastErrorFunction = (res) => {
    // setConf({ msg: "Invalid Credential !", variant: 'error' });
  };

  const filteredSidebarData = sidebarData.filter((item) =>
    item.roles.includes(userRole)
  );

  const drawer = (
    <div>
      <Toolbar className={`flex-col justify-center pl-5 pt-5 pr-5 mt-1`}>
        <img src={sidebarLogo} alt={'logo'} />
      </Toolbar>

      <List className="sidebarList ">
        {filteredSidebarData.map((text, index) => (
          <div key={index}>
            <ListItem
              disablePadding
              className={
                text?.name === intl.formatMessage({ id: 'Logout' })
                  ? `sidebarBottom ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
                  : index + 1 === filteredSidebarData?.length - 1
                    ? `mt-3 mb-5 ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
                    : `mt-3 ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
              }
            >
              <ListItemButton
                onClick={() => {
                  handleMenuItemClick(text, index);
                }}
                className={
                  activeIndex === index ||
                  (text.children && openSubmenuIndex === index)
                    ? 'active-menu-item '
                    : ''
                }
              >
                <ListItemIcon>
                  <Image
                    src={
                      activeIndex === index ||
                      (text.children && openSubmenuIndex === index)
                        ? text?.selectedIcon
                        : text?.icon
                    }
                    alt={'icon'}
                    style={{ height: '24px', width: '24px' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={text?.name}
                  className={
                    text?.name === intl.formatMessage({ id: 'Logout' })
                      ? 'Item-text'
                      : 'Item-text'
                  }
                />
              </ListItemButton>
            </ListItem>
          </div>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Row>
      <Col md={0} lg={2} className="sidebar">
        <Box sx={{ display: 'flex' }}>
          <AppBar>
            <Toolbar className="d-xxl-none menu-container">
              <Stack direction="horizontal" gap={4} className="d-flex">
                <MenuIcon
                  style={{
                    fontSize: '2rem',
                    color: '#292D32',
                    cursor: 'pointer',
                  }}
                  onClick={handleDrawerToggle}
                />
                <p className="mb-0 menu-text">
                  <FormattedMessage id="Allue" />
                </p>
              </Stack>
            </Toolbar>
          </AppBar>

          <Box component="nav" aria-label="mailbox folders">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              anchor={locale === 'en' ? 'left' : 'right'}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}
              className="d-block d-xxl-none"
              sx={{
                // display: { lg: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '240px',
                },
              }}
            >
              {drawer}
            </Drawer>

            <Drawer
              variant="permanent"
              anchor={locale === 'en' ? 'left' : 'right'}
              className="drawer-menu d-none d-xxl-block"
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  borderRight: 'none',
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
      </Col>
    </Row>
  );
};

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
