import {
  UPDATE_COMPANY_BRANCH_PROVIDER,
  UPDATE_COMPANY_BRANCH_PROVIDER_FAILURE,
  UPDATE_COMPANY_BRANCH_PROVIDER_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const updateCompanyBranchProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_BRANCH_PROVIDER:
      return { ...state, loading: true };
    case UPDATE_COMPANY_BRANCH_PROVIDER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case UPDATE_COMPANY_BRANCH_PROVIDER_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default updateCompanyBranchProviderReducer;
