import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminCustomer, superAdminCustomerDetails } from 'routes/AppUrls';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import PhotoImage from 'assets/images/provider/image4.png';
import HouseIcon from 'assets/images/provider/HouseLine.png';
import ClockIcon from 'assets/images/provider/clock.png';
import AppointmentColor from 'assets/images/sidebar/AppointmentColor.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentDetailsCallAPI } from 'store/SuperAdmin/getAppointmentDetails/action';
import moment from 'moment';

const CustomerAppointmentDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const { state } = useLocation();

  const appointmentDetailApiData = useSelector(
    (state) => state?.getAppointmentDetailsReducer?.data?.data
  );
  const [appointmentDetail, setAppointmentDetail] = useState(
    appointmentDetailApiData ? appointmentDetailApiData : ''
  );

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Customers" />,
      Link: superAdminCustomer(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Customer Details" />,
      Link: superAdminCustomerDetails(locale),
      state: { customerId: appointmentDetail?.user_details?.customer_id },
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Appointment Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  useEffect(() => {
    getAppointmentDetailsApi();
  }, []);

  const getAppointmentDetailsApi = () => {
    dispatch(
      getAppointmentDetailsCallAPI(
        { id: state?.customerId },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setAppointmentDetail(res?.data?.data);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <Col xs={12} md={12} className="mt-4 border-b pb-4 p-0">
            <div className="gap-3 flex">
              <img
                src={
                  appointmentDetail?.user_details?.profile_image !== null &&
                  appointmentDetail?.user_details?.profile_image !== undefined
                    ? appointmentDetail?.user_details?.profile_image
                    : PhotoImage
                }
                alt={'icon'}
                style={{
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%',
                }}
              />
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {appointmentDetail?.user_details?.name}
                  </p>
                </div>
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    ID : {appointmentDetail?.user_details?.customer_id}
                  </p>
                </div>
                <div className="flex gap-5 flex-wrap">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {appointmentDetail?.user_details?.contact_number}
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {appointmentDetail?.user_details?.email}
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={12} className="mt-3 border-b pb-4 p-0">
            <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Appointment Details" />
            </h5>
            <div className="flex gap-5 flex-wrap mt-3 pt-1">
              <Stack direction="horizontal" gap={2} className="items-center">
                <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                  <FormattedMessage id="Service Type" /> {':'}
                </p>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={HouseIcon}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {appointmentDetail?.service_location}
                  </p>
                </Stack>
              </Stack>

              <Stack direction="horizontal" gap={3} className="items-center">
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={AppointmentColor}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                    {appointmentDetail?.slot_date !== null &&
                    appointmentDetail?.slot_date !== undefined
                      ? moment(appointmentDetail?.slot_date).format(
                          'MMMM DD, YYYY'
                        )
                      : ''}
                  </p>
                </Stack>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={ClockIcon}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                    {appointmentDetail?.slot_start_time !== null &&
                    appointmentDetail?.slot_start_time !== undefined
                      ? moment(appointmentDetail?.slot_start_time).format(
                          'hh:mm A'
                        )
                      : ''}
                  </p>
                </Stack>
              </Stack>

              <Stack direction="horizontal" gap={2} className="items-center">
                <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                  <FormattedMessage id="Location" /> {':'}
                </p>
                <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                  {appointmentDetail?.delivery_address}
                </p>
              </Stack>
            </div>
          </Col>

          <Col xs={12} md={12} className="mt-3 border-b pb-4 p-0">
            <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Provider Details" />
            </h5>
            <div className="gap-5 flex mt-3">
              <div className="gap-3 flex">
                <img
                  src={
                    appointmentDetail?.provider_details?.profile_image !==
                      null &&
                    appointmentDetail?.provider_details?.profile_image !==
                      undefined
                      ? appointmentDetail?.provider_details?.profile_image
                      : PhotoImage
                  }
                  alt={'icon'}
                  style={{
                    height: '64px',
                    width: '64px',
                    borderRadius: '50%',
                  }}
                />
                <div className="flex-col d-flex justify-between">
                  <div>
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {appointmentDetail?.provider_details?.name_en}
                    </p>
                  </div>
                  <div>
                    <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                      ID : {appointmentDetail?.provider_details?.provider_id}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    <FormattedMessage id="Contact Info" />
                  </p>
                </div>
                <div className="flex gap-5">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {appointmentDetail?.provider_details?.contact_number}
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {appointmentDetail?.provider_details?.email}
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <h5 className="p-0 m-0 mt-3 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
            <FormattedMessage id="Services" />
          </h5>

          {appointmentDetail?.services?.length > 0 &&
            appointmentDetail.services.map((k, i) => (
              <Col xs={12} md={12} className="mt-3 border-b pb-2 p-0">
                <div className="flex justify-between">
                  <div className="gap-3 flex items-center">
                    <img
                      src={PhotoImage}
                      alt={'icon'}
                      style={{
                        height: '92px',
                        width: '92px',
                      }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {k?.service_name}
                      </p>
                      <p className="p-0 m-0 mt-3 fs-16 fc-292D32 fw-400 ff-Akkurat">
                        {k?.description}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div>
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {k?.rating} | {k?.time_required_in_mints} min
                      </p>
                      <p className="p-0 m-0 mt-3 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {k?.price} {k?.currency}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default CustomerAppointmentDetails;
