import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const COUNTRY_CITY_DROPDOWN = 'COUNTRY_CITY_DROPDOWN';
export const COUNTRY_CITY_DROPDOWN_SUCCESS = 'COUNTRY_CITY_DROPDOWN_SUCCESS';
export const COUNTRY_CITY_DROPDOWN_FAILURE = 'COUNTRY_CITY_DROPDOWN_FAILURE';

// Action Creators
export const fetchCountryCityDropdown = () => ({
  type: COUNTRY_CITY_DROPDOWN,
});

export const fetchCountryCityDropdownSuccess = (data) => ({
  type: COUNTRY_CITY_DROPDOWN_SUCCESS,
  payload: data,
});

export const fetchCountryCityDropdownFailure = (error) => ({
  type: COUNTRY_CITY_DROPDOWN_FAILURE,
  payload: error,
});

// Thunk Function
export const countryCityDropdownCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchCountryCityDropdown(data, external));
    try {
      const response = await getMethodWithToken(url.countyCityDropdownApi);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchCountryCityDropdownSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchCountryCityDropdownFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchCountryCityDropdownFailure(error.message));
    }
  };
};
