import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const TOKEN_VERIFY = 'TOKEN_VERIFY';
export const TOKEN_VERIFY_SUCCESS = 'TOKEN_VERIFY_SUCCESS';
export const TOKEN_VERIFY_FAILURE = 'TOKEN_VERIFY_FAILURE';

// Action Creators
export const fetchTokenVerify = () => ({
  type: TOKEN_VERIFY,
});

export const fetchTokenVerifySuccess = (data) => ({
  type: TOKEN_VERIFY_SUCCESS,
  payload: data,
});

export const fetchTokenVerifyFailure = (error) => ({
  type: TOKEN_VERIFY_FAILURE,
  payload: error,
});

// Thunk Function
export const tokenVerifyCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchTokenVerify(data, external));
    try {
      const response = await postMethod(url.verifyTokenProviderAdmin, data);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchTokenVerifySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchTokenVerifyFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchTokenVerifyFailure(error.message));
    }
  };
};
