import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import {
  superAdminCustomer,
  superAdminCustomerAppointmentDetails,
} from 'routes/AppUrls';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import PhotoImage from 'assets/images/provider/image4.png';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetailsCallAPI } from 'store/SuperAdmin/getCustomerDetails/action';
import moment from 'moment';

const CustomerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const { state } = useLocation();

  const customerDetailApiData = useSelector(
    (state) => state?.getCustomerDetailsReducer?.data?.data
  );
  const [customerDetail, setCustomerDetail] = useState(
    customerDetailApiData ? customerDetailApiData : ''
  );

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Customers" />,
      Link: superAdminCustomer(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Customer Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  useEffect(() => {
    getCustomerDetailsApi();
  }, []);

  const getCustomerDetailsApi = () => {
    dispatch(
      getCustomerDetailsCallAPI(
        { id: state?.customerId },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setCustomerDetail(res?.data?.data);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <Col xs={12} md={6} className="mt-4">
            <div className="gap-3 flex">
              <img
                src={
                  customerDetail?.profile_image !== null &&
                  customerDetail?.profile_image !== undefined
                    ? customerDetail?.profile_image
                    : PhotoImage
                }
                alt={'icon'}
                style={{
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%',
                }}
              />
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {customerDetail?.name}
                  </p>
                </div>
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    ID: {customerDetail?.id}
                  </p>
                </div>
                <div className="flex gap-5">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {customerDetail?.contact_number}
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      {customerDetail?.email}
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} className="mt-4 d-flex items-end">
            <div>
              <p className="p-0 m-0 fs-16 fw-400 fc-9A9A9A ff-Akkurat_Bold">
                <FormattedMessage id="Location" /> {':'}
              </p>
              <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat">
                {customerDetail?.address}
              </p>
            </div>
          </Col>
        </Row>

        <h5 className="p-0 m-0 mt-4 fs-16 fw-400 fc-292D32 ff-Akkurat_Bold">
          <FormattedMessage id="Appointment History" />
        </h5>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Price" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {customerDetail?.appoinment_history?.length > 0 &&
            customerDetail.appoinment_history.map((key, index) => (
              <Row className="m-0 p-0 flex items-center listAppointment">
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.booking_id}
                  </p>
                </Col>

                <Col lg={3} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Provider}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {key?.service_location}
                    </p>
                  </Stack>
                  <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                    {key?.delivery_address}
                  </p>
                </Col>

                <Col lg={3} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.slot_date !== null && key?.slot_date !== undefined
                      ? moment(key?.slot_date).format('DD MMM YYYY')
                      : ''}
                  </p>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.slot_start_time !== null &&
                    key?.slot_start_time !== undefined
                      ? moment(key?.slot_start_time).format('hh:mm A')
                      : ''}
                  </p>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                    {key?.prize} {key?.currency}
                  </p>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={() =>
                      navigate(superAdminCustomerAppointmentDetails(locale), {
                        state: { customerId: key?.appoinment_id },
                      })
                    }
                  >
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                      <FormattedMessage id="View Details" />
                    </p>
                    <img
                      src={rightArrow}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                  </Stack>
                </Col>
              </Row>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default CustomerDetails;
