import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import addIcon from 'assets/images/Dashboard/add.png';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import ReusableTabs from 'components/common/Tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableButton from 'components/common/Button';
import {
  superAdminAddProvider,
  superAdminCompanyProviderDetails,
  superAdminNewRequestProvider,
  superAdminOnHoldProvider,
  superAdminSalonProviderDetails,
  superAdminSpecialistProviderDetails,
} from 'routes/AppUrls';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProviderListCallAPI } from 'store/SuperAdmin/getAllProviderList/action';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import { getAllProviderInformationDetailsCallAPI } from 'store/SuperAdmin/getAllProviderInformation/action';
import { deleteProviderSuperAdminCallAPI } from 'store/SuperAdmin/deleteProvider/action';
import {
  basicDetailsCallApi,
  PROVIDER_SELECTED_TAB,
} from 'store/SuperAdmin/basicDetails/action';
import { useConfirm } from 'material-ui-confirm';
import Lottie from 'lottie-react';
import animationData from 'assets/Lottie/no_data.json';

const SuperAdminProvider = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const getProviderSelectedTab = useSelector(
    (state) => state?.basicDetailsReducer?.providerSelectedTab
  );
  const [tab, setTab] = useState(getProviderSelectedTab);
  const [page, setPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page

  console.log(tab, getProviderSelectedTab);

  const getAllProviderData = useSelector(
    (state) => state?.getAllProviderListReducer?.data
  );
  const [totalPages, setTotalPages] = useState(
    Math.ceil((getAllProviderData?.count || 0) / rowsPerPage)
  );

  const [allProviderList, setAllProviderList] = useState(
    getAllProviderData?.results?.data ? getAllProviderData?.results?.data : []
  );

  useEffect(() => {
    // getAllProvider();
    handleTabChange(tab);
  }, []);

  const getAllProvider = (page = 1, slug = '') => {
    let data = { pageSize: rowsPerPage, pageNumber: page, slug: slug };
    dispatch(
      getAllProviderListCallAPI(
        { data },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setAllProviderList(res?.data?.results?.data);
    setTotalPages(Math.ceil((res?.data?.count || 0) / rowsPerPage));
  };

  const tabData = [
    { label: intl.formatMessage({ id: 'All Providers' }) },
    { label: intl.formatMessage({ id: 'New Requests' }) },
    { label: intl.formatMessage({ id: 'On Hold' }) },
    { label: intl.formatMessage({ id: 'Requested' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
    dispatch(basicDetailsCallApi(PROVIDER_SELECTED_TAB, val));
    setPage(1);
    switch (val) {
      case 1:
        getAllProvider(1);
        break;
      case 2:
        getAllProvider(1, 'new_request');
        break;
      case 3:
        getAllProvider(1, 'on-hold');
        break;
      case 4:
        getAllProvider(1, 'new_request');
        break;
    }
  };

  const AllProviderInformationApi = (data) => {
    switch (data?.business_type) {
      case 'specialist':
        navigate(superAdminSpecialistProviderDetails(locale), {
          state: { data },
        });
        break;
      case 'salon':
        navigate(superAdminSalonProviderDetails(locale), {
          state: { data },
        });
        break;
      case 'branch-company':
        navigate(superAdminSalonProviderDetails(locale), {
          state: { data },
        });
        break;
      case 'company':
        navigate(superAdminCompanyProviderDetails(locale), {
          state: { data },
        });
        break;
    }
  };

  const handleViewDetails = (data, index) => {
    switch (tab) {
      case 1:
        AllProviderInformationApi(data);
        break;
      case 2:
        navigate(superAdminNewRequestProvider(locale), {
          state: { id: data?.id },
        });
        break;
      case 3:
        navigate(superAdminOnHoldProvider(locale), { state: { id: data.id } });
        break;
      case 4:
        break;
    }
  };

  const handleDeleteProvider = (data, index) => {
    confirm({
      title: 'Confirm Delete',
      description: 'Are you sure you want to delete Provider ?',
      confirmationText: 'Yes',
      cancellationText: 'No',
      dialogProps: { maxWidth: 'xs' },
      confirmationButtonProps: {
        sx: {
          bgcolor: '#292D32',
          color: '#FFFFFF',
          borderRadius: '0px',
          border: '1px solid #292D32',
          '&:hover': { bgcolor: '#292D32' },
        },
      },
      cancellationButtonProps: {
        sx: {
          bgcolor: 'transparent',
          color: '#292D32',
          borderRadius: '0px',
          border: '1px solid #292D32',
        },
      },
    })
      .then(() => handleConfirmDeleteProvider(data, index))
      .catch(() => console.log('Deletion cancelled.'));
  };

  const handleConfirmDeleteProvider = (data, index) => {
    dispatch(
      deleteProviderSuperAdminCallAPI(
        {},
        {
          id: data?.id,
          run: true,
          handleFunction: (res) => getAllProvider(page),
          navigate: navigate,
        }
      )
    );
  };

  ////////// Pagination ////////////////////
  const handlePageChange = (event, value) => {
    setPage(value);
    switch (tab) {
      case 1:
        getAllProvider(value);
        break;
      case 2:
        getAllProvider(value, 'new_request');
        break;
      case 3:
        getAllProvider(value, 'on-hold');
        break;
      case 4:
        getAllProvider(value, 'new_request');
        break;
    }
  };
  ////////// Pagination ////////////////////

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Provider" />{' '}
        </h2>

        <Row className="m-0 p-0 mt-3 justify-between">
          <Col md={12} lg={8} xl={8} xxl={6} className="m-0 p-0">
            <ReusableTabs
              tabs={tabData}
              defaultTab={tab}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
          <Col md={12} lg={2} xl={2} xxl={2} className="m-0 p-0">
            <ReusableButton
              fullWidth={true}
              variant="contained"
              color="#F5F5F5"
              fontSize="16px"
              fontWeight="400"
              padding="12px"
              height="48px"
              bgColor="#292D32"
              fontFamily="Akkurat"
              onClick={() => navigate(superAdminAddProvider(locale))}
            >
              <img
                src={addIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
                className="me-2"
              />
              <FormattedMessage id="Add a Provider" />
            </ReusableButton>
          </Col>
        </Row>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Providers" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Serving Location" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Contact Info" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              {tab === 1 ? (
                <FormattedMessage id="Joined On" />
              ) : (
                <FormattedMessage id="Requested On" />
              )}
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {allProviderList?.length > 0 ? (
            allProviderList.map((key, index) =>
              tab === 4 || tab === 2 ? (
                <>
                  {tab === 4 && key?.is_added_by_admin && (
                    <Row className="m-0 p-0 flex items-center listAppointment">
                      <Col lg={2} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {key?.name?.name_en}
                        </p>
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                          {key?.address}
                        </p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center "
                          >
                            <img
                              src={mail}
                              alt="icon"
                              style={{ height: '20px', width: '20px' }}
                            />
                            <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                              {key?.email}
                            </p>
                          </Stack>
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center mt-2"
                          >
                            <img
                              src={call}
                              alt="icon"
                              style={{ height: '20px', width: '20px' }}
                            />
                            <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                              {key?.contact_number}
                            </p>
                          </Stack>
                        </p>
                      </Col>
                      <Col lg={2} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                          {key?.requested_on
                            ? moment(key?.requested_on)?.format('DD MMM YYYY')
                            : ''}
                        </p>
                      </Col>
                      <Col lg={2} md={6} sm={12}>
                        <img
                          src={trash}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                      </Col>
                    </Row>
                  )}
                  {tab === 2 && key?.is_added_by_admin === false && (
                    <Row className="m-0 p-0 flex items-center listAppointment">
                      <Col lg={2} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {key?.name?.name_en}
                        </p>
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                          {key?.address}
                        </p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center "
                          >
                            <img
                              src={mail}
                              alt="icon"
                              style={{ height: '20px', width: '20px' }}
                            />
                            <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                              {key?.email}
                            </p>
                          </Stack>
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center mt-2"
                          >
                            <img
                              src={call}
                              alt="icon"
                              style={{ height: '20px', width: '20px' }}
                            />
                            <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                              {key?.contact_number}
                            </p>
                          </Stack>
                        </p>
                      </Col>
                      <Col lg={2} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                          {key?.requested_on
                            ? moment(key?.requested_on)?.format('DD MMM YYYY')
                            : ''}
                        </p>
                      </Col>
                      <Col lg={2} md={6} sm={12}>
                        <Stack
                          direction="horizontal"
                          gap={3}
                          className="items-center cursor-pointer"
                        >
                          <img
                            src={trash}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            onClick={() => handleDeleteProvider(key, index)}
                          />
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center "
                            onClick={() => handleViewDetails(key, index)}
                          >
                            <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                              <FormattedMessage id="View Details" />
                            </p>
                            <img
                              src={rightArrow}
                              alt="icon"
                              style={{ height: '20px', width: '20px' }}
                            />
                          </Stack>
                        </Stack>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {key?.name?.name_en}
                    </p>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {key?.address}
                    </p>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {key?.email}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {key?.contact_number}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {tab === 1
                        ? key?.joining_on
                          ? moment(key?.joining_on)?.format('DD MMM YYYY')
                          : ''
                        : key?.requested_on
                          ? moment(key?.requested_on)?.format('DD MMM YYYY')
                          : ''}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center cursor-pointer"
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        onClick={() => handleDeleteProvider(key, index)}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                        onClick={() => handleViewDetails(key, index)}
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              )
            )
          ) : (
            <Row className="bc-FFFFFF m-0 p-50 flex justify-center items-center">
              <Lottie
                animationData={animationData}
                loop={true}
                style={{ height: '200px', width: '200px' }}
              />
            </Row>
          )}
        </Row>

        <Row className="mt-3 m-0 p-0">
          <Stack spacing={2} className="p-0">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              className="d-flex justify-end"
            />
          </Stack>
        </Row>
      </Col>
    </Row>
  );
};

export default SuperAdminProvider;
