import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_ALL_PROVIDER_LIST_DETAILS = 'GET_ALL_PROVIDER_LIST_DETAILS';
export const GET_ALL_PROVIDER_LIST_DETAILS_SUCCESS =
  'GET_ALL_PROVIDER_LIST_DETAILS_SUCCESS';
export const GET_ALL_PROVIDER_LIST_DETAILS_FAILURE =
  'GET_ALL_PROVIDER_LIST_DETAILS_FAILURE';

// Action Creators
export const fetchGetAllProviderListDetails = () => ({
  type: GET_ALL_PROVIDER_LIST_DETAILS,
});

export const fetchGetAllProviderListDetailsSuccess = (data) => ({
  type: GET_ALL_PROVIDER_LIST_DETAILS_SUCCESS,
  payload: data,
});

export const fetchGetAllProviderListDetailsFailure = (error) => ({
  type: GET_ALL_PROVIDER_LIST_DETAILS_FAILURE,
  payload: error,
});

// Thunk Function
export const getAllProviderListDetailsCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetAllProviderListDetails(data, external));
    try {
      const response = await getMethodWithToken(
        url.getAllProviderListDetailsSuperAdmin + data?.data?.id + '/'
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAllProviderListDetailsSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetAllProviderListDetailsFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAllProviderListDetailsFailure(error.message));
    }
  };
};
