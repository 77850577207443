import { postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const ADD_SERVICE = 'ADD_SERVICE';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAILURE = 'ADD_SERVICE_FAILURE';

// Action Creators
export const fetchAddService = () => ({
  type: ADD_SERVICE,
});

export const fetchAddServiceSuccess = (data) => ({
  type: ADD_SERVICE_SUCCESS,
  payload: data,
});

export const fetchAddServiceFailure = (error) => ({
  type: ADD_SERVICE_FAILURE,
  payload: error,
});

// Thunk Function
export const addServiceCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchAddService(data, external));
    try {
      const userRole = getUserRole();
      const response = await postMethodWithToken(
        userRole === 'super-admin'
          ? url.addServiceListSuperAdmin
          : url.addSubCategoryServiceProviderAdmin,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchAddServiceSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchAddServiceFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchAddServiceFailure(error.message));
    }
  };
};
