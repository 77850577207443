// Action Types
export const BASIC_DETAILS = 'BASIC_DETAILS';
export const ACTIVE_INDEX = 'ACTIVE_INDEX';
export const SUB_CATEGORY_DETAILS = 'SUB_CATEGORY_DETAILS';
export const SERVICE_DETAILS = 'SERVICE_DETAILS';
export const SUB_SERVICE_DETAILS = 'SUB_SERVICE_DETAILS';
export const SELECTED_TAB = 'SELECTED_TAB';
export const SPECIALIST_SELECTED_TAB = 'SPECIALIST_SELECTED_TAB';
export const SPECIALIST_DETAILS_SELECTED_TAB =
  'SPECIALIST_DETAILS_SELECTED_TAB';
export const DASHBOARD_NEW_USER_SELECTED_TAB =
  'DASHBOARD_NEW_USER_SELECTED_TAB';
export const PROVIDER_SELECTED_TAB = 'PROVIDER_SELECTED_TAB';
export const PROVIDER_PROFILE_SELECTED_TAB = 'PROVIDER_PROFILE_SELECTED_TAB';

// This action will simply save the details to Redux without any API call
export const basicDetailsCallApi = (type, details) => {
  switch (type) {
    case BASIC_DETAILS:
      return {
        type: BASIC_DETAILS,
        payload: details,
      };
    case ACTIVE_INDEX:
      return {
        type: ACTIVE_INDEX,
        payload: details,
      };
    case SUB_CATEGORY_DETAILS:
      return {
        type: SUB_CATEGORY_DETAILS,
        payload: details,
      };
    case SERVICE_DETAILS:
      return {
        type: SERVICE_DETAILS,
        payload: details,
      };
    case SUB_SERVICE_DETAILS:
      return {
        type: SUB_SERVICE_DETAILS,
        payload: details,
      };
    case SELECTED_TAB:
      return {
        type: SELECTED_TAB,
        payload: details,
      };
    case SPECIALIST_SELECTED_TAB:
      return {
        type: SPECIALIST_SELECTED_TAB,
        payload: details,
      };
    case SPECIALIST_DETAILS_SELECTED_TAB:
      return {
        type: SPECIALIST_DETAILS_SELECTED_TAB,
        payload: details,
      };
    case DASHBOARD_NEW_USER_SELECTED_TAB:
      return {
        type: DASHBOARD_NEW_USER_SELECTED_TAB,
        payload: details,
      };
    case PROVIDER_SELECTED_TAB:
      return {
        type: PROVIDER_SELECTED_TAB,
        payload: details,
      };
    case PROVIDER_PROFILE_SELECTED_TAB:
      return {
        type: PROVIDER_PROFILE_SELECTED_TAB,
        payload: details,
      };
  }
};
