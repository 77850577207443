import React, { useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import SpecialistOnboarding from 'components/feature/specialist/specialistOnboarding';
import SalonOnboarding from 'components/feature/salon/salonOnboarding';
import CompanyOnboarding from 'components/feature/company/companyOnboarding';
import { getUserRole } from 'utils/general';

const ProviderOnboarding = () => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const { locale } = useContext(LocaleContext);

  return (
    <Container fluid>
      <Row className="bc-F2F3F6 d-flex justify-center items-center">
        <Col xs={12} md={10} className="m-auto bc-FFFFFF mt-5 mb-5 p-0">
          {userRole === 'specialist-admin' && <SpecialistOnboarding />}
          {userRole === 'company-admin' && <CompanyOnboarding />}
          {userRole === 'salon-admin' && <SalonOnboarding />}
          {userRole === 'branch-company' && <SalonOnboarding />}
        </Col>
      </Row>
    </Container>
  );
};

export default ProviderOnboarding;
