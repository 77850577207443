import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { providerAdminSpecialists } from 'routes/AppUrls';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ReusableButton from 'components/common/Button';
import { getUserRole } from 'utils/general';
import { countryCityDropdownCallAPI } from 'store/CountryCityList/action';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { imageUploadCallAPI } from 'store/ImageUpload/action';
import ToastNotification from 'components/common/Toast';
import CheckValidation from 'utils/Validation';
import { createSpecialistProviderCallAPI } from 'store/ProviderAdmin/createSpecialistProvider/action';
import { getCompanyBranchProviderCallAPI } from 'store/ProviderAdmin/getCompanyBranchProvider/action';
import CircularProgress from '@mui/material/CircularProgress';

const AddSpecialist = () => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const brandImageFileInputRef = useRef();
  const specialistImageRef = useRef();
  const [brandImageFile, setBrandImageFile] = useState(null);
  const [specialistImages, setSpecialistImages] = useState([]);

  const [isError, setIsError] = useState(null);
  const phoneNumberInputRef = useRef(null);
  const alternatePhoneNumberInputRef = useRef(null);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isAlternatePhoneNumberValid, setIsAlternatePhoneNumberValid] =
    useState(true);

  const countryCityData = useSelector(
    (state) => state?.countryCityDropdownReducer?.data?.data
  );
  const getBranchListData = useSelector(
    (state) => state?.getCompanyBranchProviderReducer?.data?.data
  );
  const addSpecialistLoader = useSelector(
    (state) => state?.createSpecialistProviderReducer?.loading
  );
  const profileData = useSelector((state) => state?.userMeReducer?.data?.data);

  const [formValue, setFormValue] = useState({
    specialistName: '',
    addressArea: '',
    country: '',
    city: '',
    branch: '',
    phoneNumber: '',
    countryCode: '+974',
    alternatePhoneNumber: '',
    countryCodeAlternateNumber: '+974',
    email: '',
    alternateEmail: '',
    about: '',
    brandImage: '',
    bussinessImages: [],
  });

  const [formId, setFormId] = useState({
    country: '',
    city: '',
    branch: '',
  });

  useEffect(() => {
    getCountryCityList();
    {
      userRole === 'company-admin' && getBranchList();
    }
  }, []);

  const getBranchList = () => {
    dispatch(
      getCompanyBranchProviderCallAPI(
        {},
        {
          navigate: navigate,
        }
      )
    );
  };

  const getCountryCityList = () => {
    dispatch(countryCityDropdownCallAPI({}, { naviagte: navigate }));
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Specialists" />,
      Link: providerAdminSpecialists(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Specialist’s Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value?.name || value?.searched_business_name_en,
    });
    setFormId({ ...formId, [name]: value?.id });
  };

  const handleBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSuccess(res),
        })
      );
      setBrandImageFile(URL.createObjectURL(file));
    }
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue({ ...formValue, ['brandImage']: res?.data?.data?.[0]?.id });
  };

  const handleSpecialistImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSpecialistImagesSuccess(res),
        })
      );
      setSpecialistImages([...specialistImages, URL.createObjectURL(file)]);
    }
  };

  const handleSpecialistImagesSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue({
      ...formValue,
      ['bussinessImages']: [
        ...formValue?.bussinessImages,
        res?.data?.data?.[0]?.id,
      ],
    });
  };

  const handleBrandImageUpload = () => {
    brandImageFileInputRef.current.click();
  };

  const handleSpecialistImageUpload = () => {
    specialistImageRef.current.click();
  };

  const handleDeleteSpecialistImage = (data, index) => {
    const filterImages = specialistImages?.filter((value) => value !== data);
    setSpecialistImages(filterImages);
  };

  const handleCountryCodeChange = (value, name) => {
    if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: value });
    } else if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: value });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumberValue = e.target.value;
    setFormValue((prev) => ({
      ...prev,
      [e.target.name]: phoneNumberValue,
    }));

    if (e.target.name === 'phoneNumber') {
      const fullPhoneNumber = `+${formValue?.countryCode}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsPhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsPhoneNumberValid(false);
      }
    } else if (e.target.name === 'alternatePhoneNumber') {
      const fullPhoneNumber = `+${formValue?.countryCodeAlternateNumber}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsAlternatePhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsAlternatePhoneNumberValid(false);
      }
    }
  };

  const handleSubmit = () => {
    const { specialistName, country, city, phoneNumber, email } = formValue;
    const error = CheckValidation({
      specialistName,
      country,
      city,
      phoneNumber,
      email,
    });
    setIsError(error);
    if (error.submit) {
      let body = {
        master_provider_id:
          userRole === 'company-admin'
            ? formId?.branch
            : profileData?.provider_details?.id,
        specialist_name_en: formValue?.specialistName,
        specialist_name_ar: formValue?.specialistName,
        address: formValue?.addressArea,
        country: formId?.country,
        city: formId?.city,
        contact_number: formValue?.phoneNumber,
        is_contact_verified: false,
        alternate_contact_number: formValue?.alternatePhoneNumber,
        is_alternate_contact_verified: false,
        email: formValue?.email,
        is_email_verified: false,
        alternate_email: formValue?.alternateEmail,
        is_alternate_email_verified: false,
        about_information_en: formValue?.about,
        about_information_ar: formValue?.about,
        profile_photo: formValue?.brandImage,
        branch_images: formValue?.bussinessImages,
      };
      dispatch(
        createSpecialistProviderCallAPI(body, {
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    navigate(providerAdminSpecialists(locale));
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
            <FormattedMessage id="Specialist Details" />
          </h2>

          <Row className="m-0 p-0">
            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Specialist’s Name" />}
                placeholder={intl.formatMessage({
                  id: 'Specialist’s Name',
                })}
                name="specialistName"
                type="text"
                value={formValue?.specialistName}
                onChange={handleChange}
                errorText={
                  isError &&
                  isError.specialistName.error &&
                  isError.specialistName.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              {userRole === 'company-admin' && (
                <SelectInput
                  label={
                    <FormattedMessage id="Select a branch to add the Specialist to" />
                  }
                  placeholder={intl.formatMessage({
                    id: 'Select a branch to add the Specialist to',
                  })}
                  name={'branch'}
                  value={formValue?.branch}
                  data={getBranchListData ?? []}
                  onChange={(e) => handleInputChange(e)}
                />
              )}
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Address of the serving area" />}
                placeholder={intl.formatMessage({
                  id: 'Address of the serving area',
                })}
                name="addressArea"
                type="text"
                value={formValue?.addressArea}
                onChange={handleChange}
              />
            </Col>

            <Col xs={12} md={3} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="Country" />}
                placeholder={intl.formatMessage({ id: 'Country' })}
                name={'country'}
                value={formValue?.country}
                data={countryCityData}
                onChange={(e) => handleInputChange(e)}
                errorText={
                  isError &&
                  isError.country.error &&
                  isError.country.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={3} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="City" />}
                placeholder={intl.formatMessage({ id: 'City' })}
                name={'city'}
                value={formValue?.city}
                data={
                  countryCityData?.find(
                    (value) => value?.name === formValue?.country
                  )?.city || []
                }
                onChange={(e) => handleInputChange(e)}
                errorText={
                  isError &&
                  isError.country.error &&
                  isError.country.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label={<FormattedMessage id="Contact Number" />}
                country={'qa'}
                value={formValue?.countryCode}
                name={'phoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'phoneNumber')
                }
                onPhoneNumberChange={handlePhoneNumberChange}
                phoneNumber={formValue.phoneNumber}
                phoneNumberRef={phoneNumberInputRef}
                isPhoneNumberValid={isPhoneNumberValid}
                isError={
                  isError &&
                  isError.phoneNumber.error &&
                  isError.phoneNumber.errorMessage
                }
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label={<FormattedMessage id="Alternate Contact Number" />}
                country={'qa'}
                value={formValue?.countryCodeAlternateNumber}
                name={'alternatePhoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'alternatePhoneNumber')
                }
                onPhoneNumberChange={handlePhoneNumberChange}
                phoneNumber={formValue.alternatePhoneNumber}
                phoneNumberRef={alternatePhoneNumberInputRef}
                isPhoneNumberValid={isAlternatePhoneNumberValid}
                // isError={isError}
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Email ID" />}
                placeholder={intl.formatMessage({ id: 'Email ID' })}
                name="email"
                type="text"
                value={formValue?.email}
                onChange={handleChange}
                errorText={
                  isError && isError.email.error && isError.email.errorMessage
                }
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Alternate Email ID" />}
                placeholder={intl.formatMessage({
                  id: 'Alternate Email ID',
                })}
                name="alternateEmail"
                type="text"
                value={formValue?.alternateEmail}
                onChange={handleChange}
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="About/Information" />}
                placeholder={intl.formatMessage({
                  id: 'About/Information',
                })}
                height="100"
                multiline
                name="about"
                value={formValue?.about}
                onChange={(e) => handleChange(e)}
              />
            </Col>

            <input
              ref={brandImageFileInputRef}
              type="file"
              accept=".png, .jpg, .jpeg"
              disabled={false}
              style={{ display: 'none' }}
              onChange={handleBrandImageFileChange}
            />

            <div className="mt-4">
              <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Photos" />
              </p>
              <Stack
                direction="horizontal"
                gap={4}
                className="items-center mt-4"
              >
                <div className="AvtarImageIcon">
                  {brandImageFile && (
                    <img
                      src={brandImageFile}
                      alt={'icon'}
                      style={{
                        height: '88px',
                        width: '88px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <img
                    src={ProfileIcon}
                    alt="icon"
                    style={{ height: '32px', width: '32px' }}
                    className="profileIcon"
                    onClick={handleBrandImageUpload}
                  />
                </div>
                <div>
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Upload Brand Image" />
                  </p>
                  <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="A picture will help people recognize you" />
                  </p>
                </div>
              </Stack>
            </div>

            <Col xs={12} md={12} className="mt-3">
              <div className="flex justify-between items-center">
                <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Images" />
                </p>
                {specialistImages?.length > 0 && (
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#292D32"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="transparent"
                    fontFamily="Akkurat"
                    onClick={handleSpecialistImageUpload}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      className="me-2"
                    />
                    <FormattedMessage id="Add Images" />
                  </ReusableButton>
                )}
              </div>

              <input
                ref={specialistImageRef}
                type="file"
                accept=".png, .jpg, .jpeg"
                disabled={false}
                style={{ display: 'none' }}
                onChange={handleSpecialistImageChange}
              />

              {specialistImages?.length <= 0 ? (
                <div className="mt-2 bussinessImages flex justify-center align-center">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={handleSpecialistImageUpload}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="AddImageEn" />
                    </p>
                  </Stack>
                </div>
              ) : (
                <div className="flex flex-wrap gap-2 mt-3">
                  {specialistImages.map((key, index) => (
                    <div className="relative">
                      <img
                        src={key}
                        alt="image"
                        style={{ height: '122px', width: '246px' }}
                      />
                      <img
                        src={crossIcon}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        className="absolute top-2 right-2 cursor-pointer"
                        onClick={() => handleDeleteSpecialistImage(key, index)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-4 items-end m-0 p-0">
            <div className="flex justify-end">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                  endIcon={
                    addSpecialistLoader && (
                      <CircularProgress
                        style={{
                          color: 'white',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )
                  }
                  onClick={() => handleSubmit()}
                >
                  <FormattedMessage id="Send Invitation" />
                </ReusableButton>
              </Stack>
            </div>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default AddSpecialist;
