import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import Avtar from 'assets/images/Dashboard/avtar.png';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import CustomPopover from 'components/common/CustomPopover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { superAdminAppointmentsDetails } from 'routes/AppUrls';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getAppointmentCallAPI } from 'store/SuperAdmin/getAppointmentList/action';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import { cancelAppointmentSuperAdminCallAPI } from 'store/SuperAdmin/cancelAppointment/action';
import Lottie from 'lottie-react';
import animationData from 'assets/Lottie/no_data.json';

const Appointments = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewData, setViewData] = useState('');

  const [page, setPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page

  const appointmentListData = useSelector(
    (state) => state?.getAppointmentReducer?.data
  );
  const [totalPages, setTotalPages] = useState(
    Math.ceil((appointmentListData?.count || 0) / rowsPerPage)
  );
  const [appointmentList, setAppointmentList] = useState(
    appointmentListData ? appointmentListData : []
  );

  const handlePopoverClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setViewData(data);
  };

  const handleCancelAppointment = (data) => {
    dispatch(
      cancelAppointmentSuperAdminCallAPI(
        {},
        {
          id: data?.id,
          run: true,
          handleFunction: (res) => getAppointmentListApi(rowsPerPage, page),
          navigate: navigate,
        }
      )
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const PopoverData = [
    {
      label: <FormattedMessage id="View Details" />,
      icon: (
        <VisibilityOutlinedIcon
          style={{ marginRight: '5px', marginTop: '-5px' }}
        />
      ),
      onClick: () =>
        navigate(superAdminAppointmentsDetails(locale), {
          state: { customerId: viewData?.id },
        }),
    },
    {
      label: <FormattedMessage id="Delete" />,
      icon: (
        <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />
      ),
      onClick: () => handleCancelAppointment(viewData),
    },
  ];

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAppointmentListApi(rowsPerPage, page);
  }, []);

  const getAppointmentListApi = (pageSize, page) => {
    dispatch(
      getAppointmentCallAPI(
        { pageSize, page },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setAppointmentList(res?.data);
    setTotalPages(Math.ceil((res?.data?.count || 0) / rowsPerPage));
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getAppointmentListApi(rowsPerPage, value);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Appointments" />
        </h2>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Customer" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Provider" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12} className="text-start">
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {appointmentList?.results?.length > 0 ? (
            appointmentList.results.map((key, index) => (
              <Row className="m-0 p-0 flex items-center listAppointment">
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={key?.customers_image ? key?.customers_image : Avtar}
                      alt="icon"
                      style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '50%',
                      }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {key?.customers_data?.customers_name}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.customers_data?.customers_id}
                      </p>
                    </div>
                  </Stack>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={key?.provider_image ? key?.provider_image : Avtar}
                      alt="icon"
                      style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '50%',
                      }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {key?.provider_data?.name_en}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {key?.provider_data?.provider_id}
                      </p>
                    </div>
                  </Stack>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Provider}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {key?.service_location}
                    </p>
                  </Stack>
                  <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1 truncate">
                    {key?.delivery_address}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.booking_id}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.slot_date !== null && key?.slot_date !== undefined
                      ? moment(key?.slot_date).format('DD MMM YYYY')
                      : ''}
                  </p>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.slot_start_time !== null &&
                    key?.slot_start_time !== undefined
                      ? moment(key?.slot_start_time).format('h:mm A')
                      : ''}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12} className="text-start">
                  <div>
                    <MoreVertIcon
                      aria-describedby={id}
                      style={{ fill: '#3C1053', cursor: 'pointer' }}
                      onClick={(e) => handlePopoverClick(e, key)}
                    />
                    <CustomPopover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handlePopoverClose}
                      options={PopoverData}
                    />
                  </div>
                </Col>
              </Row>
            ))
          ) : (
            <Row className="bc-FFFFFF m-0 p-50 flex justify-center items-center">
              <Lottie
                animationData={animationData}
                loop={true}
                style={{ height: '200px', width: '200px' }}
              />
            </Row>
          )}
        </Row>

        <Row className="mt-3 m-0 p-0">
          <Stack spacing={2} className="p-0">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              className="d-flex justify-end"
            />
          </Stack>
        </Row>
      </Col>
    </Row>
  );
};

export default Appointments;
