import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { getUserRole } from 'utils/general';

// Action Types
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

// Action Creators
export const fetchGetCustomer = () => ({
  type: GET_CUSTOMER_LIST,
});

export const fetchGetCustomerSuccess = (data) => ({
  type: GET_CUSTOMER_LIST_SUCCESS,
  payload: data,
});

export const fetchGetCustomerFailure = (error) => ({
  type: GET_CUSTOMER_LIST_FAILURE,
  payload: error,
});

const getRole = (userRole, data) => {
  switch (userRole) {
    case 'company-admin':
      return (
        url.getCustomerDataProviderAdmin +
        '?page_size=' +
        data?.pageSize +
        '&page=' +
        data?.page
      );
    case 'salon-admin':
      return (
        url.getBranchSalonCustomerListProviderAdmin +
        '?page_size=' +
        data?.pageSize +
        '&page=' +
        data?.page
      );
    case 'branch-company':
      return (
        url.getBranchSalonCustomerListProviderAdmin +
        '?page_size=' +
        data?.pageSize +
        '&page=' +
        data?.page
      );
    case 'specialist-admin':
      return (
        url.getCustomerListSpecialistProviderAdmin +
        '?page_size=' +
        data?.pageSize +
        '&page=' +
        data?.page
      );
  }
};

// Thunk Function
export const getCustomerProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetCustomer(data, external));
    const userRole = getUserRole();
    try {
      const userRole = getUserRole();
      const response = await getMethodWithToken(getRole(userRole, data));
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetCustomerSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetCustomerFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetCustomerFailure(error.message));
    }
  };
};
