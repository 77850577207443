import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';

// Action Creators
export const fetchGetDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
});

export const fetchGetDashboardDataSuccess = (data) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const fetchGetDashboardDataFailure = (error) => ({
  type: GET_DASHBOARD_DATA_FAILURE,
  payload: error,
});

// Thunk Function
export const getDashboardDataCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetDashboardData(data, external));
    try {
      const response = await getMethodWithToken(
        url.getDashboardDataSuperAdmin + '?query=' + external?.type
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetDashboardDataSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetDashboardDataFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetDashboardDataFailure(error.message));
    }
  };
};
