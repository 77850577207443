import React from 'react';
import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Styled Tabs
const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${(props) => props.indicatorColor || 'blue'};
  }
  .MuiTabs-flexContainer {
    display: flex !important;
    justify-content: space-between !important;
  }
`;

// Styled Tab
const StyledTab = styled(Tab)`
  font-size: ${(props) => props.fontSize || '1rem'} !important;
  color: ${(props) => props.color || 'black'} !important;
  font-family: 'Akkuart_Bold';
  &.Mui-selected {
    color: ${(props) => props.selectedColor || 'blue'} !important;
    font-family: 'Akkuart_Bold';
  }
`;

const CustomTabs = ({ tabs, value, onChange, tabStyles, indicatorColor }) => {
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        indicatorColor={indicatorColor}
      >
        {tabs?.length > 0 &&
          tabs.map((tab) => (
            <StyledTab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              {...tabStyles}
              wrapped
            />
          ))}
      </StyledTabs>
    </Box>
  );
};

export default CustomTabs;
