import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const REGISTER_INVITE_BUSINESS = 'REGISTER_INVITE_BUSINESS';
export const REGISTER_INVITE_BUSINESS_SUCCESS =
  'REGISTER_INVITE_BUSINESS_SUCCESS';
export const REGISTER_INVITE_BUSINESS_FAILURE =
  'REGISTER_INVITE_BUSINESS_FAILURE';

// Action Creators
export const fetchRegisterInviteBusiness = () => ({
  type: REGISTER_INVITE_BUSINESS,
});

export const fetchRegisterInviteBusinessSuccess = (data) => ({
  type: REGISTER_INVITE_BUSINESS_SUCCESS,
  payload: data,
});

export const fetchRegisterInviteBusinessFailure = (error) => ({
  type: REGISTER_INVITE_BUSINESS_FAILURE,
  payload: error,
});

// Thunk Function
export const registerInviteBusinessCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchRegisterInviteBusiness(data, external));
    try {
      const response = await postMethod(url.registerInviteProviderAdmin, data);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchRegisterInviteBusinessSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchRegisterInviteBusinessFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchRegisterInviteBusinessFailure(error.message));
    }
  };
};
