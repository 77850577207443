import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_COMPANY_BRANCH_PROVIDER = 'GET_COMPANY_BRANCH_PROVIDER';
export const GET_COMPANY_BRANCH_PROVIDER_SUCCESS =
  'GET_COMPANY_BRANCH_PROVIDER_SUCCESS';
export const GET_COMPANY_BRANCH_PROVIDER_FAILURE =
  'GET_COMPANY_BRANCH_PROVIDER_FAILURE';

// Action Creators
export const fetchGetCompanyBranchProvider = () => ({
  type: GET_COMPANY_BRANCH_PROVIDER,
});

export const fetchGetCompanyBranchProviderSuccess = (data) => ({
  type: GET_COMPANY_BRANCH_PROVIDER_SUCCESS,
  payload: data,
});

export const fetchGetCompanyBranchProviderFailure = (error) => ({
  type: GET_COMPANY_BRANCH_PROVIDER_FAILURE,
  payload: error,
});

// Thunk Function
export const getCompanyBranchProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetCompanyBranchProvider(data, external));
    try {
      const response = await getMethodWithToken(url.getCompanyBranchProvider);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetCompanyBranchProviderSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetCompanyBranchProviderFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetCompanyBranchProviderFailure(error.message));
    }
  };
};
