import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_ALL_PROVIDER_LIST = 'GET_ALL_PROVIDER_LIST';
export const GET_ALL_PROVIDER_LIST_SUCCESS = 'GET_ALL_PROVIDER_LIST_SUCCESS';
export const GET_ALL_PROVIDER_LIST_FAILURE = 'GET_ALL_PROVIDER_LIST_FAILURE';

// Action Creators
export const fetchGetAllProviderList = () => ({
  type: GET_ALL_PROVIDER_LIST,
});

export const fetchGetAllProviderListSuccess = (data) => ({
  type: GET_ALL_PROVIDER_LIST_SUCCESS,
  payload: data,
});

export const fetchGetAllProviderListFailure = (error) => ({
  type: GET_ALL_PROVIDER_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getAllProviderListCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetAllProviderList(data, external));
    try {
      const response = await getMethodWithToken(
        url.getAllProviderListSuperAdmin +
          data?.data?.pageSize +
          '&page=' +
          data?.data?.pageNumber +
          '&slug=' +
          data?.data?.slug
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAllProviderListSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetAllProviderListFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAllProviderListFailure(error.message));
    }
  };
};
