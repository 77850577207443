import { postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const ADD_COMPANY_PROVIDER = 'ADD_COMPANY_PROVIDER';
export const ADD_COMPANY_PROVIDER_SUCCESS = 'ADD_COMPANY_PROVIDER_SUCCESS';
export const ADD_COMPANY_PROVIDER_FAILURE = 'ADD_COMPANY_PROVIDER_FAILURE';

// Action Creators
export const fetchAddCompanyProvider = () => ({
  type: ADD_COMPANY_PROVIDER,
});

export const fetchAddCompanyProviderSuccess = (data) => ({
  type: ADD_COMPANY_PROVIDER_SUCCESS,
  payload: data,
});

export const fetchAddCompanyProviderFailure = (error) => ({
  type: ADD_COMPANY_PROVIDER_FAILURE,
  payload: error,
});

// Thunk Function
export const addCompanyProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchAddCompanyProvider(data, external));
    try {
      const response = await postMethodWithToken(
        url.addCompanyProviderSuperAdmin,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchAddCompanyProviderSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchAddCompanyProviderFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchAddCompanyProviderFailure(error.message));
    }
  };
};
