import { getMethodWithToken, putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { getUserRole } from 'utils/general';

// Action Types
export const GET_CUSTOMER_DETAIL_DATA = 'GET_CUSTOMER_DETAIL_DATA';
export const GET_CUSTOMER_DETAIL_DATA_SUCCESS =
  'GET_CUSTOMER_DETAIL_DATA_SUCCESS';
export const GET_CUSTOMER_DETAIL_DATA_FAILURE =
  'GET_CUSTOMER_DETAIL_DATA_FAILURE';

// Action Creators
export const fetchGetCustomerDetail = () => ({
  type: GET_CUSTOMER_DETAIL_DATA,
});

export const fetchGetCustomerDetailSuccess = (data) => ({
  type: GET_CUSTOMER_DETAIL_DATA_SUCCESS,
  payload: data,
});

export const fetchGetCustomerDetailFailure = (error) => ({
  type: GET_CUSTOMER_DETAIL_DATA_FAILURE,
  payload: error,
});

const getRole = (userRole, data) => {
  switch (userRole) {
    case 'company-admin':
      return url.getCustomerDetailDataProviderAdmin + data?.id + '/';
    case 'salon-admin':
      return url.getCustomerDetailsSalonProviderAdmin + data?.id + '/';
    case 'branch-company':
      return url.getCustomerDetailsSalonProviderAdmin + data?.id + '/';
    case 'specialist-admin':
      return url.getCustomerDetailListSpecialistProviderAdmin + data?.id + '/';
  }
};

// Thunk Function
export const getCustomerDetailProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetCustomerDetail(data, external));
    try {
      const userRole = getUserRole();
      const response = await getMethodWithToken(getRole(userRole, data));
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetCustomerDetailSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetCustomerDetailFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetCustomerDetailFailure(error.message));
    }
  };
};
