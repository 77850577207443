import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminProvider } from 'routes/AppUrls';
import ReusableButton from 'components/common/Button';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import Specialist from 'assets/images/Dashboard/Specialist.png';
import CompanyProvider from 'components/feature/company';
import SalonProvider from 'components/feature/salon';
import SpecialistProvider from 'components/feature/specialist';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProviderListDetailsCallAPI } from 'store/SuperAdmin/getAllProviderListDetails/action';
import moment from 'moment';
import { promoteProviderCallAPI } from 'store/SuperAdmin/promoteProvider/action';
import ToastNotification from 'components/common/Toast';
import { onHoldProviderCallAPI } from 'store/SuperAdmin/onHoldProvider/action';
import CircularProgress from '@mui/material/CircularProgress';

const NewRequestProvider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const location = useLocation();
  const [requestDetail, setRequestDetail] = useState('');

  const onHoldLoader = useSelector(
    (state) => state?.onHoldProviderReducer?.loading
  );
  const promoteLoader = useSelector(
    (state) => state?.promoteProviderReducer?.loading
  );

  useEffect(() => {
    getRequestDetails();
  }, []);

  const getRequestDetails = () => {
    let data = {
      id: location?.state?.id,
    };

    dispatch(
      getAllProviderListDetailsCallAPI(
        { data },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const OnHoldProvider = () => {
    let data = {
      id: location?.state?.id,
    };

    dispatch(
      onHoldProviderCallAPI(
        { data },
        {
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        }
      )
    );
  };

  const PromoteProvider = () => {
    let data = {
      id: location?.state?.id,
    };

    dispatch(
      promoteProviderCallAPI(
        { data },
        {
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    navigate(superAdminProvider(locale));
  };

  const handleSuccess = (res) => {
    setRequestDetail(res?.data?.data);
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Providers" />,
      Link: superAdminProvider(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Request Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(superAdminProvider(locale))}
                />
                <p className="p-0 m-0">
                  <FormattedMessage id="Request Details" />
                </p>
              </Stack>
            </h2>
            <div className="flex">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                  endIcon={
                    onHoldLoader && (
                      <CircularProgress
                        style={{
                          color: 'black',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )
                  }
                  onClick={() => OnHoldProvider()}
                >
                  <FormattedMessage id="Put On Hold" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                  endIcon={
                    promoteLoader && (
                      <CircularProgress
                        style={{
                          color: 'white',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )
                  }
                  onClick={() => PromoteProvider()}
                >
                  <FormattedMessage id="Promote" />
                </ReusableButton>
              </Stack>
            </div>
          </div>

          <Row className="m-0 p-0 mt-4 pt-2">
            <Col xs={12} md={4} className="items-center">
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                <FormattedMessage id="Requested by" />
              </p>
              <p className="m-0 p-0 fc-292D32 fs-18 fw-400 ff-Akkurat_Bold">
                {requestDetail?.name?.name_en}
              </p>
            </Col>
            <Col xs={12} md={4} className="items-center text-center">
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                <FormattedMessage id="Business Type" /> {':'}
              </p>
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center justify-center"
              >
                <img
                  src={Specialist}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                />
                <p
                  className="m-0 p-0 fc-292D32 fs-16 fw-400 ff-Akkurat_Bold"
                  className="p-0 m-0"
                >
                  {requestDetail?.business_type}
                </p>
              </Stack>
            </Col>
            <Col xs={12} md={4} className="items-center text-center">
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                <FormattedMessage id="Request Date" />
              </p>
              <p className="m-0 p-0 fc-292D32 fs-16 fw-400 ff-Akkurat">
                {requestDetail?.requested_on
                  ? moment(requestDetail?.requested_on).format('DD MMM YYYY')
                  : ''}
              </p>
            </Col>
          </Row>

          <div className="mt-4">
            <p className="p-0 m-0 fc-292D32 fs-18 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Business Details" />
            </p>
          </div>

          {requestDetail?.business_type === 'salon' && (
            <SalonProvider data={requestDetail} />
          )}

          {requestDetail?.business_type === 'branch-company' && (
            <SalonProvider data={requestDetail} />
          )}

          {requestDetail?.business_type === 'company' && (
            <CompanyProvider data={requestDetail} />
          )}

          {requestDetail?.business_type === 'specialist' && (
            <SpecialistProvider data={requestDetail} />
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default NewRequestProvider;
