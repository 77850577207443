import {
  BASIC_DETAILS,
  SELECTED_TAB,
  SUB_CATEGORY_DETAILS,
  SERVICE_DETAILS,
  SUB_SERVICE_DETAILS,
  ACTIVE_INDEX,
  SPECIALIST_SELECTED_TAB,
  SPECIALIST_DETAILS_SELECTED_TAB,
  DASHBOARD_NEW_USER_SELECTED_TAB,
  PROVIDER_SELECTED_TAB,
  PROVIDER_PROFILE_SELECTED_TAB,
} from './action';

const initialState = {
  editCategory: null,
  subCategoryData: null,
  serviceDetails: null,
  subServiceDetails: null,
  selectedTab: 0,
  spcialistSelectedTab: 1,
  spcialistDetailsSelectedTab: 1,
  activeIndex: 0,
  dashboardNewUserSelectedTab: 1,
  providerSelectedTab: 1,
  providerProfileSelectedTab: 1,
};

const basicDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BASIC_DETAILS:
      return {
        ...state,
        editCategory: action.payload,
      };
    case ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload,
      };
    case SUB_CATEGORY_DETAILS:
      return {
        ...state,
        subCategoryData: action.payload,
      };
    case SERVICE_DETAILS:
      return {
        ...state,
        serviceDetails: action.payload,
      };
    case SUB_SERVICE_DETAILS:
      return {
        ...state,
        subServiceDetails: action.payload,
      };
    case SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SPECIALIST_SELECTED_TAB:
      return {
        ...state,
        spcialistSelectedTab: action.payload,
      };
    case SPECIALIST_DETAILS_SELECTED_TAB:
      return {
        ...state,
        spcialistDetailsSelectedTab: action.payload,
      };
    case DASHBOARD_NEW_USER_SELECTED_TAB:
      return {
        ...state,
        dashboardNewUserSelectedTab: action.payload,
      };
    case PROVIDER_SELECTED_TAB:
      return {
        ...state,
        providerSelectedTab: action.payload,
      };
    case PROVIDER_PROFILE_SELECTED_TAB:
      return {
        ...state,
        providerProfileSelectedTab: action.payload,
      };
    default:
      return state;
  }
};

export default basicDetailsReducer;
