import { putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const UPDATE_ENABLE_DISABLE_SERVICES = 'UPDATE_ENABLE_DISABLE_SERVICES';
export const UPDATE_ENABLE_DISABLE_SERVICES_SUCCESS =
  'UPDATE_ENABLE_DISABLE_SERVICES_SUCCESS';
export const UPDATE_ENABLE_DISABLE_SERVICES_FAILURE =
  'UPDATE_ENABLE_DISABLE_SERVICES_FAILURE';

// Action Creators
export const fetchUpdateEnableDisableServices = () => ({
  type: UPDATE_ENABLE_DISABLE_SERVICES,
});

export const fetchUpdateEnableDisableServicesSuccess = (data) => ({
  type: UPDATE_ENABLE_DISABLE_SERVICES_SUCCESS,
  payload: data,
});

export const fetchUpdateEnableDisableServicesFailure = (error) => ({
  type: UPDATE_ENABLE_DISABLE_SERVICES_FAILURE,
  payload: error,
});

// Thunk Function
export const updateEnableDisableServicesCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchUpdateEnableDisableServices(data, external));
    try {
      const response = await putMethodWithToken(
        url.enableDisableSerivces,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchUpdateEnableDisableServicesSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchUpdateEnableDisableServicesFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchUpdateEnableDisableServicesFailure(error.message));
    }
  };
};
