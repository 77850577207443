import { combineReducers } from 'redux';
import loginReducer from 'store/login/loginReducer';
import basicDetailsReducer from 'store/SuperAdmin/basicDetails/reducer';
import addCategoryReducer from 'store/SuperAdmin/addCategory/reducer';
import addSubCategoryReducer from 'store/SuperAdmin/addSubCategory/reducer';
import addServiceReducer from 'store/SuperAdmin/addService/reducer';
import deleteCategoryReducer from 'store/SuperAdmin/deleteCategory/reducer';
import deleteSubCategoryReducer from 'store/SuperAdmin/deleteSubCategory/reducer';
import deleteServiceReducer from 'store/SuperAdmin/deleteService/reducer';
import getCategoryReducer from 'store/SuperAdmin/getCategory/reducer';
import getSubCategoryReducer from 'store/SuperAdmin/getSubCategory/reducer';
import updateCategoryReducer from 'store/SuperAdmin/updateCategory/reducer';
import updateSubCategoryReducer from 'store/SuperAdmin/updateSubCategory/reducer';
import updateServiceReducer from 'store/SuperAdmin/updateService/reducer';
import addAppointmentsReducer from 'store/ProviderAdmin/addAppointments/reducer';
import getAppointmentsReducer from 'store/ProviderAdmin/getAppointments/reducer';
import updateAppointmentsReducer from 'store/ProviderAdmin/updateAppointments/reducer';
import registerInviteBusinessReducer from 'store/ProviderAdmin/registerInviteBusiness/reducer';
import createPasswordReducer from 'store/ProviderAdmin/createPassword/reducer';
import tokenVerifyReducer from 'store/ProviderAdmin/tokenVerify/reducer';
import getAllProviderListReducer from 'store/SuperAdmin/getAllProviderList/reducer';
import getAllProviderListDetailsReducer from 'store/SuperAdmin/getAllProviderListDetails/reducer';
import promoteProviderReducer from 'store/SuperAdmin/promoteProvider/reducer';
import onHoldProviderReducer from 'store/SuperAdmin/onHoldProvider/reducer';
import addSalonProviderReducer from 'store/SuperAdmin/addSalonProvider/reducer';
import addCompanyProviderReducer from 'store/SuperAdmin/addCompanyProvider/reducer';
import countryCityDropdownReducer from 'store/CountryCityList/reducer';
import imageUploadReducer from 'store/ImageUpload/reducer';
import updateAllProviderListDetailsReducer from 'store/SuperAdmin/updateAllProviderListDetails/reducer';
import addCompanyBranchProviderReducer from 'store/ProviderAdmin/addCompanyBranchProvider/reducer';
import userMeReducer from 'store/UserMeDetails/reducer';
import getCompanyBranchProviderReducer from 'store/ProviderAdmin/getCompanyBranchProvider/reducer';
import createSpecialistProviderReducer from 'store/ProviderAdmin/createSpecialistProvider/reducer';
import getSpecialistProviderReducer from 'store/ProviderAdmin/getSpecialistProvider/reducer';
import updateSpecialistStatusProviderReducer from 'store/ProviderAdmin/statusUpdateSpecialistProvider/reducer';
import getAllProviderInformationDetailsReducer from 'store/SuperAdmin/getAllProviderInformation/reducer';
import getBasicProviderInforrmationReducer from 'store/SuperAdmin/basicProviderInformation/reducer';
import getBranchDetailsProviderReducer from 'store/ProviderAdmin/getBranchDetailsProvider/reducer';
import deleteBranchListReducer from 'store/ProviderAdmin/deleteBranchProvider/reducer';
import getDashboardDataReducer from 'store/SuperAdmin/dashboard/reducer';
import getCustomerReducer from 'store/SuperAdmin/getCustomerList/reducer';
import getAppointmentReducer from 'store/SuperAdmin/getAppointmentList/reducer';
import getAppointmentDetailsReducer from 'store/SuperAdmin/getAppointmentDetails/reducer';
import getCustomerDetailsReducer from 'store/SuperAdmin/getCustomerDetails/reducer';
import getDashboardProviderDataReducer from 'store/ProviderAdmin/dashboard/reducer';
import cancelSpecialistInvitationReducer from 'store/ProviderAdmin/cancelSpecialistInvitation/reducer';
import updateCompanyBranchProviderReducer from 'store/ProviderAdmin/updateCompanyBranchProvider/reducer';
import getCustomerProviderReducer from 'store/ProviderAdmin/getCustomerList/reducer';
import getAppointmentsListReducer from 'store/ProviderAdmin/getAppointmentList/reducer';
import getCustomerDetailProviderReducer from 'store/ProviderAdmin/customerDetails/reducer';
import getAppointmentDaysReducer from 'store/AppointmentDaysList/reducer';
import deleteProviderReducer from 'store/SuperAdmin/deleteProvider/reducer';
import cancelAppointmentReducer from 'store/SuperAdmin/cancelAppointment/reducer';
import cancelAppointmentProviderAdminReducer from 'store/ProviderAdmin/cancelAppointment/reducer';
import updateProfileReducer from 'store/ProviderAdmin/updateProfile/reducer';
import deleteCustomerProviderReducer from 'store/ProviderAdmin/deleteCustomer/reducer';
import deleteCustomerSuperAdminReducer from 'store/SuperAdmin/deleteCustomer/reducer';
import updateEnableDisableServicesReducer from 'store/SuperAdmin/updateEnableDisableService/reducer';

export default combineReducers({
  loginReducer,
  basicDetailsReducer,
  addCategoryReducer,
  addSubCategoryReducer,
  addServiceReducer,
  deleteCategoryReducer,
  deleteSubCategoryReducer,
  deleteServiceReducer,
  getCategoryReducer,
  getSubCategoryReducer,
  updateCategoryReducer,
  updateSubCategoryReducer,
  updateServiceReducer,
  addAppointmentsReducer,
  getAppointmentsReducer,
  updateAppointmentsReducer,
  registerInviteBusinessReducer,
  createPasswordReducer,
  tokenVerifyReducer,
  getAllProviderListReducer,
  getAllProviderListDetailsReducer,
  promoteProviderReducer,
  onHoldProviderReducer,
  addSalonProviderReducer,
  addCompanyProviderReducer,
  countryCityDropdownReducer,
  imageUploadReducer,
  updateAllProviderListDetailsReducer,
  addCompanyBranchProviderReducer,
  userMeReducer,
  getCompanyBranchProviderReducer,
  createSpecialistProviderReducer,
  getSpecialistProviderReducer,
  updateSpecialistStatusProviderReducer,
  getAllProviderInformationDetailsReducer,
  getBasicProviderInforrmationReducer,
  getBranchDetailsProviderReducer,
  deleteBranchListReducer,
  getDashboardDataReducer,
  getCustomerReducer,
  getAppointmentReducer,
  getAppointmentDetailsReducer,
  getCustomerDetailsReducer,
  getDashboardProviderDataReducer,
  cancelSpecialistInvitationReducer,
  updateCompanyBranchProviderReducer,
  getCustomerProviderReducer,
  getAppointmentsListReducer,
  getCustomerDetailProviderReducer,
  getAppointmentDaysReducer,
  deleteProviderReducer,
  cancelAppointmentReducer,
  cancelAppointmentProviderAdminReducer,
  updateProfileReducer,
  deleteCustomerProviderReducer,
  deleteCustomerSuperAdminReducer,
  updateEnableDisableServicesReducer,
});
