import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { getUserRole } from 'utils/general';

// Action Types
export const GET_APPOINTMENT_DETAILS = 'GET_APPOINTMENT_DETAILS';
export const GET_APPOINTMENT_DETAILS_SUCCESS =
  'GET_APPOINTMENT_DETAILS_SUCCESS';
export const GET_APPOINTMENT_DETAILS_FAILURE =
  'GET_APPOINTMENT_DETAILS_FAILURE';

// Action Creators
export const fetchGetAppointmentDetails = () => ({
  type: GET_APPOINTMENT_DETAILS,
});

export const fetchGetAppointmentDetailsSuccess = (data) => ({
  type: GET_APPOINTMENT_DETAILS_SUCCESS,
  payload: data,
});

export const fetchGetAppointmentDetailsFailure = (error) => ({
  type: GET_APPOINTMENT_DETAILS_FAILURE,
  payload: error,
});

const getRole = (userRole, data) => {
  switch (userRole) {
    case 'company-admin':
      return (
        url.getAppointmentListSpecialistDetailsProviderAdmin + data?.id + '/'
      );
    case 'salon-admin':
      return (
        url.getAppointmentListSpecialistDetailsProviderAdmin + data?.id + '/'
      );
    case 'branch-company':
      return (
        url.getAppointmentListSpecialistDetailsProviderAdmin + data?.id + '/'
      );
    case 'specialist-admin':
      return (
        url.getAppointmentListSpecialistDetailsProviderAdmin + data?.id + '/'
      );
    case 'super-admin':
      return url.getAppointmentDetailsDataSuperAdmin + data?.id + '/details/';
  }
};

// Thunk Function
export const getAppointmentDetailsCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetAppointmentDetails(data, external));
    try {
      const userRole = getUserRole();
      const response = await getMethodWithToken(getRole(userRole, data));

      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAppointmentDetailsSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetAppointmentDetailsFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAppointmentDetailsFailure(error.message));
    }
  };
};
