import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const getDashboardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return { ...state, loading: true };
    case GET_DASHBOARD_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case GET_DASHBOARD_DATA_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default getDashboardDataReducer;
