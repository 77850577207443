import { postMethodWithoutTokenWIthFormData } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE';

// Action Creators
export const fetchImageUpload = () => ({
  type: IMAGE_UPLOAD,
});

export const fetchImageUploadSuccess = (data) => ({
  type: IMAGE_UPLOAD_SUCCESS,
  payload: data,
});

export const fetchImageUploadFailure = (error) => ({
  type: IMAGE_UPLOAD_FAILURE,
  payload: error,
});

// Thunk Function
export const imageUploadCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchImageUpload(data, external));
    try {
      const response = await postMethodWithoutTokenWIthFormData(
        url.imageUploadApi,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchImageUploadSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchImageUploadFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchImageUploadFailure(error.message));
    }
  };
};
