import { putMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS';
export const UPDATE_SUB_CATEGORY_FAILURE = 'UPDATE_SUB_CATEGORY_FAILURE';

// Action Creators
export const fetchUpdateSubCategory = () => ({
  type: UPDATE_SUB_CATEGORY,
});

export const fetchUpdateSubCategorySuccess = (data) => ({
  type: UPDATE_SUB_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchUpdateSubCategoryFailure = (error) => ({
  type: UPDATE_SUB_CATEGORY_FAILURE,
  payload: error,
});

// Thunk Function
export const updateSubCategoryCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchUpdateSubCategory(data, external));
    try {
      const userRole = getUserRole();
      const response = await putMethodWithToken(
        userRole === 'super-admin'
          ? url.updateSubCategoryListSuperAdmin +
              external?.id +
              '/' +
              'update' +
              '/'
          : url.updateSubCategoryServiceProviderAdmin + external?.id + '/',
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchUpdateSubCategorySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchUpdateSubCategoryFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchUpdateSubCategoryFailure(error.message));
    }
  };
};
