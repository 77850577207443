import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import { superAdminCustomerDetails } from 'routes/AppUrls';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerCallAPI } from 'store/SuperAdmin/getCustomerList/action';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import ToastNotification from 'components/common/Toast';
import { deleteCustomerSuperAdminCallAPI } from 'store/SuperAdmin/deleteCustomer/action';
import { useConfirm } from 'material-ui-confirm';
import Lottie from 'lottie-react';
import animationData from 'assets/Lottie/no_data.json';

const Customer = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const [page, setPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Items per page

  const customerListData = useSelector(
    (state) => state?.getCustomerReducer?.data
  );
  const [totalPages, setTotalPages] = useState(
    Math.ceil((customerListData?.count || 0) / rowsPerPage)
  );
  const [customerList, setCustomerList] = useState(
    customerListData ? customerListData : []
  );

  useEffect(() => {
    getCustomerListApi(rowsPerPage, page);
  }, []);

  const getCustomerListApi = (pageSize, page) => {
    dispatch(
      getCustomerCallAPI(
        { pageSize, page },
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setCustomerList(res?.data);
    setTotalPages(Math.ceil((res?.data?.count || 0) / rowsPerPage));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getCustomerListApi(rowsPerPage, value);
  };

  const handleDeleteCustomer = (key) => {
    confirm({
      title: 'Confirm Delete',
      description: 'Are you sure you want to delete Customer ?',
      confirmationText: 'Yes',
      cancellationText: 'No',
      dialogProps: { maxWidth: 'xs' },
      confirmationButtonProps: {
        sx: {
          bgcolor: '#292D32',
          color: '#FFFFFF',
          borderRadius: '0px',
          border: '1px solid #292D32',
          '&:hover': { bgcolor: '#292D32' },
        },
      },
      cancellationButtonProps: {
        sx: {
          bgcolor: 'transparent',
          color: '#292D32',
          borderRadius: '0px',
          border: '1px solid #292D32',
        },
      },
    })
      .then(() => handleConfirmDeleteCustomer(key))
      .catch(() => console.log('Deletion cancelled.'));
  };

  const handleConfirmDeleteCustomer = (key) => {
    dispatch(
      deleteCustomerSuperAdminCallAPI(
        {},
        {
          id: key?.id,
          run: true,
          handleFunction: (res) => handleDeleteSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleDeleteSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    getCustomerListApi(rowsPerPage, page);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Customer" />
        </h2>

        <Row className="mt-3 listCustomHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Customer & ID" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Location" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Contact Info" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Joined On" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {customerList?.results?.length > 0 ? (
            customerList.results.map((key, index) => (
              <Row className="m-0 p-0 flex items-center listAppointment">
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {key?.name?.name_en}
                  </p>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {key?.id}
                  </p>
                </Col>

                <Col lg={3} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                    {key?.address}
                  </p>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center "
                    >
                      <img
                        src={mail}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                        {key?.email}
                      </p>
                    </Stack>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center mt-2"
                    >
                      <img
                        src={call}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                        {key?.contact_number}
                      </p>
                    </Stack>
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                    {key?.joining_on !== null && key?.joining_on !== undefined
                      ? moment(key?.joining_on).format('DD MMM YYYY')
                      : ''}
                  </p>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center "
                  >
                    <img
                      className="cursor-pointer"
                      src={trash}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      onClick={() => handleDeleteCustomer(key)}
                    />
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center cursor-pointer"
                      onClick={() =>
                        navigate(superAdminCustomerDetails(locale), {
                          state: { customerId: key?.id },
                        })
                      }
                    >
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                        <FormattedMessage id="View Details" />
                      </p>
                      <img
                        src={rightArrow}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                    </Stack>
                  </Stack>
                </Col>
              </Row>
            ))
          ) : (
            <Row className="bc-FFFFFF m-0 p-50 flex justify-center items-center">
              <Lottie
                animationData={animationData}
                loop={true}
                style={{ height: '200px', width: '200px' }}
              />
            </Row>
          )}
        </Row>

        {customerList?.results?.length > 0 && (
          <Row className="mt-3 m-0 p-0">
            <Stack spacing={2} className="p-0">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                className="d-flex justify-end"
              />
            </Stack>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Customer;
