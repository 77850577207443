import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'assets/styles/common.scss';
import 'react-calendar/dist/Calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'Pages/ProviderAdmin/providerAppointments/appointments.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Stack } from 'react-bootstrap';
import { LocaleContext } from 'routes/Router';
import EditIcon from 'assets/images/Tab/editIcon.png';
import AddIcon from 'assets/images/Dashboard/add.png';
import ReusableButton from 'components/common/Button';
import CustomTabs from 'components/common/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmensCallAPI } from 'store/ProviderAdmin/getAppointments/action';
import { useNavigate } from 'react-router-dom';
import {
  providerAdminEditAppointments,
  superAdminAppointmentsDetails,
} from 'routes/AppUrls';
import CheckBoxInput from 'components/common/Input/CheckBoxInput';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import ToastNotification from 'components/common/Toast';
import { addAppointmentsCallAPI } from 'store/ProviderAdmin/addAppointments/action';
import CircularProgress from '@mui/material/CircularProgress';
import { getAppointmentsListCallAPI } from 'store/ProviderAdmin/getAppointmentList/action';
import { getAppointmentDaysProviderCallAPI } from 'store/AppointmentDaysList/action';

const localizer = momentLocalizer(moment);

const ProviderAppointments = () => {
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tab, setTab] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [tabDetails, setTabDetails] = useState([]);

  const getAppointmentsData = useSelector(
    (state) => state?.getAppointmentsReducer?.data?.data
  );
  const [appointmentsListData, setAppointmentsListData] = useState(
    getAppointmentsData ? getAppointmentsData : []
  );

  const getAppointmentsProviderListData = useSelector(
    (state) => state?.getAppointmentsListReducer?.data?.data
  );
  const [appointmentsListProviderData, setAppointmentsListProviderData] =
    useState(
      getAppointmentsProviderListData ? getAppointmentsProviderListData : []
    );

  const addAppointmentLoader = useSelector(
    (state) => state?.addAppointmentsReducer?.loading
  );

  useEffect(() => {
    getAppointmentDaysList();
    getAppointmentsList();
    getAppointmentsSalonList();
  }, []);

  const getAppointmentsList = () => {
    dispatch(
      getAppointmensCallAPI(
        {},
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const getAppointmentsSalonList = () => {
    dispatch(
      getAppointmentsListCallAPI(
        {},
        {
          run: true,
          handleFunction: (res) => handleAppointmentProviderSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleAppointmentProviderSuccess = (res) => {
    let appointmentData = [];
    if (
      res?.data?.data?.length > 0 &&
      res?.data?.data.map((key, index) =>
        appointmentData.push({
          start: new Date(key?.slot_start_time),
          end: new Date(key?.slot_end_time),
          title: `Appointment with ${key?.customers_data?.customers_name}`,
          id: key?.id,
        })
      )
    )
      setAppointmentsListProviderData(appointmentData);
  };

  const handleSuccess = (res) => {
    setAppointmentsListData(res?.data?.data);
  };

  const formatDate = (date) => moment(date).format('YYYY-MM-DD');
  const filteredEvents = appointmentsListProviderData.filter(
    (event) => formatDate(event.start) === formatDate(selectedDate)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const highlightWeekends = ({ date }) => {
    const today = new Date().toDateString();
    const day = date.getDay();
    if (date.toDateString() === selectedDate?.toDateString()) return 'selected';
    if (date.toDateString() === today) return 'today';
    if (day === 0) return 'sunday';
    if (day === 6) return 'saturday';
    return null;
  };

  const handleNavigationClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const getAppointmentDaysList = () => {
    dispatch(
      getAppointmentDaysProviderCallAPI(
        {},
        {
          run: true,
          handleFunction: (res) => handleAppointmentDaysListSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleAppointmentDaysListSuccess = (res) => {
    if (!res?.data?.data || res.data.data.length === 0) return;
    const appointmentDaysData = res.data.data.map((key) => ({
      label: key?.name?.slice(0, 3),
      slug: key?.slug,
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    }));
    setTabData(appointmentDaysData);
    setTabDetails(appointmentDaysData);
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const formatShortWeekday = (locale, date) => {
    const weekdays = {
      en: ['S', 'M', 'T', 'W', 'T', 'F', 'S'], // English initials
      ar: ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'], // Arabic initials
    };

    return locale === 'ar'
      ? weekdays.ar[date.getDay()]
      : weekdays.en[date.getDay()];
  };

  ////////////TabShiftDetails/////////////////////////

  const handleShiftChange = (tabIndex, field, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].shift[field] = value;
    setTabDetails(updatedDetails);
  };

  const handleBreakChange = (tabIndex, breakIndex, field, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].breaks[breakIndex][field] = value;
    setTabDetails(updatedDetails);
  };

  const handleAddBreak = (tabIndex) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].breaks.push({
      break_start_time: '',
      break_end_time: '',
    });
    setTabDetails(updatedDetails);
  };

  const handleSaveDetails = (tabValue) => {
    const indicesToSave = tabDetails.findIndex(
      (tab) => tab.label === tabValue.label
    );
    let body = {
      schedules: [
        {
          is_holiday: tabDetails[indicesToSave]?.isHoliday,
          day_of_week: tabDetails[indicesToSave]?.slug,
        },
      ],
    };
    if (!tabDetails[indicesToSave]?.isHoliday) {
      body.schedules[0].start_time = tabDetails?.[indicesToSave]?.shift?.start;
      body.schedules[0].end_time = tabDetails?.[indicesToSave]?.shift?.end;
      body.schedules[0].schedule_breaks = tabDetails?.[indicesToSave]?.breaks;
    }
    dispatch(
      addAppointmentsCallAPI(body, {
        navigate: navigate,
        run: true,
        toast: true,
        handleFunction: (res) => handleRedirect(res),
        handleToastError: (res) => handleError(res),
      })
    );
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    getAppointmentsList();
  };

  const handleError = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
  };

  const handleCreateAppointments = (tabValue, tabIndex) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].isShow = true;
    setTabDetails(updatedDetails);
  };

  const tabShiftDetails = (tabValue, tabIndex) => {
    const currentTabDetails = tabDetails[tabIndex];
    const isHolidayDisabled = currentTabDetails?.isHoliday ? 'opacity-50' : '';

    return (
      <>
        {currentTabDetails?.isShow ? (
          <>
            <div className="mt-3">
              <CheckBoxInput
                label={<FormattedMessage id="Mark as Holiday" />}
                size="14"
                fontWeight="500"
                onChange={(e) => {
                  const updatedDetails = [...tabDetails];
                  updatedDetails[tabIndex].isHoliday = e.target.checked;
                  setTabDetails(updatedDetails);
                }}
                checked={currentTabDetails.isHoliday}
              />
            </div>

            <h3
              className={`p-0 m-0 mt-4 fs-16 fc-292D32 ff-Akkurat_Bold ${isHolidayDisabled}`}
            >
              <FormattedMessage id="Shift Details" />
            </h3>

            <Row className={`m-0 p-0 ${isHolidayDisabled}`}>
              <Col md={12} lg={6} className="">
                <LabelWithInput
                  label={<FormattedMessage id="Start Time" />}
                  type="time"
                  value={currentTabDetails.shift.start}
                  disabled={currentTabDetails.isHoliday}
                  onChange={(e) =>
                    handleShiftChange(tabIndex, 'start', e.target.value)
                  }
                />
              </Col>

              <Col md={12} lg={6} className="">
                <LabelWithInput
                  label={<FormattedMessage id="End Time" />}
                  type="time"
                  value={currentTabDetails.shift.end}
                  disabled={currentTabDetails.isHoliday}
                  onChange={(e) =>
                    handleShiftChange(tabIndex, 'end', e.target.value)
                  }
                />
              </Col>
            </Row>

            <h3
              className={`p-0 m-0 mt-4 fs-16 fc-292D32 ff-Akkurat_Bold ${isHolidayDisabled}`}
            >
              <FormattedMessage id="Break(s)" />
            </h3>

            <Row className={`m-0 p-0 ${isHolidayDisabled}`}>
              {currentTabDetails.breaks.map((breakTime, index) => (
                <>
                  <h3 className="p-0 m-0 mt-4 fs-14 fc-292D32 ff-Akkurat ">
                    <FormattedMessage id={`Break`} />
                    {index + 1}
                  </h3>

                  <Col md={12} lg={6} className="">
                    <LabelWithInput
                      label={<FormattedMessage id="Start Time" />}
                      type="time"
                      value={breakTime.break_start_time}
                      disabled={currentTabDetails.isHoliday}
                      onChange={(e) =>
                        handleBreakChange(
                          tabIndex,
                          index,
                          'break_start_time',
                          e.target.value
                        )
                      }
                    />
                  </Col>

                  <Col md={12} lg={6} className="">
                    <LabelWithInput
                      label={<FormattedMessage id="End Time" />}
                      type="time"
                      value={breakTime.break_end_time}
                      disabled={currentTabDetails.isHoliday}
                      onChange={(e) =>
                        handleBreakChange(
                          tabIndex,
                          index,
                          'break_end_time',
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </>
              ))}
            </Row>

            <Row className={`m-0 p-0 mt-3 ${isHolidayDisabled}`}>
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#1A1A1A"
                fontSize="14px"
                fontWeight="500"
                padding="12px 25px"
                height="40px"
                bgColor="transparent"
                brColor="#DDDDDD"
                fontFamily="Akkuat"
                disabled={currentTabDetails.isHoliday}
                onClick={() => handleAddBreak(tabIndex)}
              >
                <img
                  src={AddIcon}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="me-2"
                />
                <FormattedMessage id="Add break" />
              </ReusableButton>
            </Row>

            <Row className="m-0 p-0 mt-4 flex text-end items-center justify-end">
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center justify-end p-0"
              >
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#1A1A1A"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 50px"
                  height="44px"
                  bgColor="transparent"
                  brColor="#DDDDDD"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#FFFFFF"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 50px"
                  height="44px"
                  bgColor="#1A1A1A"
                  brColor="#1A1A1A"
                  fontFamily="Akkurat"
                  endIcon={
                    addAppointmentLoader && (
                      <CircularProgress
                        style={{
                          color: 'white',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )
                  }
                  onClick={() => handleSaveDetails(tabValue)}
                >
                  <FormattedMessage id="Save" />
                </ReusableButton>
              </Stack>
            </Row>
          </>
        ) : (
          <>
            <div className="flex-col items-center justify-center text-center mt-5 pt-5 mb-5 pb-5">
              <div className="mt-5 pt-5">
                <p className="p-0 m-0 fs-16 fc-#1A1A1A ff-Akkurat">
                  <FormattedMessage id="Show your availability to the customer" />
                </p>
              </div>
              <div className="mt-4 mb-5 pb-5">
                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#FFFFFF"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 50px"
                  height="44px"
                  bgColor="#1A1A1A"
                  brColor="#1A1A1A"
                  fontFamily="Akkurat"
                  onClick={() => handleCreateAppointments(tabValue, tabIndex)}
                >
                  <img
                    src={AddIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Create New" />
                </ReusableButton>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  ////////////TabShiftDetails/////////////////////////

  // const CustomEvent = ({ event }) => {
  //   return (
  //     <div className="custom-event">
  //       <strong>{moment(event.start).format('hh:mm A')}</strong>{' '}
  //       <span> {event.title}</span>
  //     </div>
  //   );
  // };

  const handleEventClick = (event) => {
    navigate(superAdminAppointmentsDetails(locale), {
      state: { customerId: event?.id },
    });
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 family_outfit_semiBold_600  m-0">
          <FormattedMessage id="Schedule" />
        </h2>

        {appointmentsListData.length > 0 ? (
          <div className="flex gap-4 p-0 mt-3">
            <>
              <div className="calendar-container">
                <Calendar
                  next2Label={null}
                  prev2Label={null}
                  tileClassName={highlightWeekends}
                  // formatShortWeekday={(locale, date) =>
                  //   date.toString().charAt(0)
                  // }
                  formatShortWeekday={formatShortWeekday}
                  onChange={handleDateChange}
                  value={selectedDate}
                  locale={locale === 'en' ? 'en' : 'ar'}
                  navigationLabel={({ date, label }) => (
                    <button
                      onClick={handleNavigationClick}
                      style={{
                        minWidth: '44px',
                        background: 'none',
                        cursor: 'not-allowed',
                      }}
                      disabled
                    >
                      {label}
                    </button>
                  )}
                />
              </div>

              <div className="w-100">
                <div className="flex justify-between">
                  <h2 className="p-0 m-0 fs-24 fw-400 fc-292D32 ff-Akkurat">
                    {/* {moment(selectedDate).format('MMM DD, YYYY')} */}
                    {selectedDate &&
                      (locale === 'ar'
                        ? new Intl.DateTimeFormat('ar-EG', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          }).format(new Date(selectedDate))
                        : new Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          }).format(new Date(selectedDate)))}
                  </h2>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={() =>
                      navigate(providerAdminEditAppointments(locale))
                    }
                  >
                    <img
                      src={EditIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-C56616 family_outfit_medium_500 ">
                      <FormattedMessage id="Edit Timing" />
                    </p>
                  </Stack>
                </div>
                <div className="big-calendar-container mt-3">
                  <BigCalendar
                    key={`${selectedDate}-${filteredEvents.length}`}
                    localizer={localizer}
                    events={filteredEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 'auto' }}
                    views={['day']}
                    defaultView="day"
                    date={selectedDate}
                    toolbar={false}
                    dayLayoutAlgorithm="no-overlap"
                    onSelectEvent={handleEventClick}
                    // components={{
                    //   event: CustomEvent, // Override event display
                    // }}
                  />
                </div>
              </div>
            </>
          </div>
        ) : (
          tabData?.length > 0 && (
            <div className="flex gap-4 p-0 mt-3">
              <Row className="p-0 m-0 w-100">
                <Col
                  md={12}
                  lg={12}
                  className=" border-1 bColor-FFFFFF bc-FFFFFF p-4"
                >
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                  >
                    <p className="p-0 m-0 fs-20 fc-#1A1A1A ff-Akkurat_Bold ">
                      <FormattedMessage id="Create Schedule" />
                    </p>
                  </Stack>

                  <div className="mt-3 w-100">
                    <CustomTabs
                      tabs={tabData}
                      value={tab}
                      onChange={handleTabChange}
                      tabStyles={{
                        fontSize: '18px',
                        color: '#787878',
                        selectedColor: '#1A1A1A ',
                      }}
                      indicatorColor="#1A1A1A"
                    />
                  </div>

                  {tabData.map((k, i) => tab === i && tabShiftDetails(k, i))}
                </Col>
              </Row>
            </div>
          )
        )}
      </Col>
    </Row>
  );
};

export default ProviderAppointments;
