import {
  deleteMethodWithToken,
  putMethodWithToken,
} from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const CANCEL_APPOINTMENT_SUPERADMIN = 'CANCEL_APPOINTMENT_SUPERADMIN';
export const CANCEL_APPOINTMENT_SUPERADMIN_SUCCESS =
  'CANCEL_APPOINTMENT_SUPERADMIN_SUCCESS';
export const CANCEL_APPOINTMENT_SUPERADMIN_FAILURE =
  'CANCEL_APPOINTMENT_SUPERADMIN_FAILURE';

// Action Creators
export const fetchCancelAppointmentSuperAdmin = () => ({
  type: CANCEL_APPOINTMENT_SUPERADMIN,
});

export const fetchCancelAppointmentSuperAdminSuccess = (data) => ({
  type: CANCEL_APPOINTMENT_SUPERADMIN_SUCCESS,
  payload: data,
});

export const fetchCancelAppointmentSuperAdminFailure = (error) => ({
  type: CANCEL_APPOINTMENT_SUPERADMIN_FAILURE,
  payload: error,
});

// Thunk Function
export const cancelAppointmentSuperAdminCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchCancelAppointmentSuperAdmin(data, external));
    try {
      const response = await putMethodWithToken(
        url.cancelAppointmentSuperAdmin + external?.id + '/'
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchCancelAppointmentSuperAdminSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchCancelAppointmentSuperAdminFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchCancelAppointmentSuperAdminFailure(error.message));
    }
  };
};
