import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_SPECIALIST_PROVIDER = 'GET_SPECIALIST_PROVIDER';
export const GET_SPECIALIST_PROVIDER_SUCCESS =
  'GET_SPECIALIST_PROVIDER_SUCCESS';
export const GET_SPECIALIST_PROVIDER_FAILURE =
  'GET_SPECIALIST_PROVIDER_FAILURE';

// Action Creators
export const fetchGetSpecialistProvider = () => ({
  type: GET_SPECIALIST_PROVIDER,
});

export const fetchGetSpecialistProviderSuccess = (data) => ({
  type: GET_SPECIALIST_PROVIDER_SUCCESS,
  payload: data,
});

export const fetchGetSpecialistProviderFailure = (error) => ({
  type: GET_SPECIALIST_PROVIDER_FAILURE,
  payload: error,
});

// Thunk Function
export const getSpecialistProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetSpecialistProvider(data, external));
    try {
      const response = await getMethodWithToken(
        url.getSpecialistProvider +
          external?.id +
          '/list/' +
          '?query_for=' +
          external?.type
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetSpecialistProviderSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        dispatch(fetchGetSpecialistProviderFailure('Error'));
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetSpecialistProviderFailure(error.message));
    }
  };
};
