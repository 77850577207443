import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage } from 'react-intl';
import SpecialistProfile from './specialistProfile';
import SalonProfile from './salonProfile';
import CompanyProfile from './companyProfile';
import { getUserRole } from 'utils/general';

const ProviderAdminProfile = () => {
  const userRole = getUserRole();
  const { locale } = useContext(LocaleContext);

  return (
    <>
      <Row className="bc-F2F3F6 m-0 p-0">
        <Col
          className={
            locale === 'en'
              ? 'commonEnContainer lg:ml-left'
              : 'commonArContainer lg:mr-right'
          }
        >
          <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
            <FormattedMessage id="Profile" />
          </h2>
          {userRole === 'specialist-admin' && <SpecialistProfile />}
          {userRole === 'company-admin' && <CompanyProfile />}
          {userRole === 'salon-admin' && <SalonProfile />}
          {userRole === 'branch-company' && <SalonProfile />}
        </Col>
      </Row>
    </>
  );
};

export default ProviderAdminProfile;
