import AddBranchOnboarding from 'components/feature/branch/AddBranchOnboarding';
import AddBranch from 'Pages/SuperAdmin/Provider/addBranchProvider';
import CompanyDetails from 'components/feature/company/companyDetails';
import SalonDetails from 'components/feature/salon/salonDetails';
import SpecialistDetails from 'components/feature/specialist/specialistDetails';
import CreatePassword from 'Pages/ProviderAdmin/CreatePassword';
import GetStarted from 'Pages/ProviderAdmin/GetStarted';
import ProviderOnboarding from 'Pages/ProviderAdmin/ProviderOnboarding';
import Appointments from 'Pages/SuperAdmin/Appointments';
import AppointmentsDetails from 'Pages/SuperAdmin/Appointments/AppointmentsDetails';
import Campaigns from 'Pages/SuperAdmin/Campaigns';
import AddCampaign from 'Pages/SuperAdmin/Campaigns/AddCampaign';
import Categories from 'Pages/SuperAdmin/Categories';
import AddCategory from 'Pages/SuperAdmin/Categories/AddCategory';
import AddService from 'Pages/SuperAdmin/Categories/AddService';
import AddSubCategory from 'Pages/SuperAdmin/Categories/AddSubCategory';
import EditCategory from 'Pages/SuperAdmin/Categories/EditCategory';
import EditService from 'Pages/SuperAdmin/Categories/EditService';
import EditSubCategory from 'Pages/SuperAdmin/Categories/EditSubCategory';
import Customer from 'Pages/SuperAdmin/Cutomers';
import CustomerAppointmentDetails from 'Pages/SuperAdmin/Cutomers/CustomerAppoinmentDetails';
import CustomerDetails from 'Pages/SuperAdmin/Cutomers/customerDetails';
import Dashboard from 'Pages/SuperAdmin/Dashboard';
import Login from 'Pages/SuperAdmin/Login';
import SuperAdminProvider from 'Pages/SuperAdmin/Provider';
import AddProvider from 'Pages/SuperAdmin/Provider/addProvider';
import NewRequestProvider from 'Pages/SuperAdmin/Provider/newRequestProvider';
import OnHoldProvider from 'Pages/SuperAdmin/Provider/onHoldProvider';
import Request from 'Pages/SuperAdmin/Request';
import {
  createPassword,
  getLandingURL,
  getStarted,
  login,
  providerAdminAddBranches,
  providerAdminAddService,
  providerAdminAddSpecialists,
  providerAdminAddSubCategory,
  providerAdminAppointments,
  providerAdminBranches,
  providerAdminBranchesDetails,
  providerAdminCategory,
  providerAdminCustomer,
  providerAdminCustomerDetails,
  providerAdminDashboard,
  providerAdminEditAppointments,
  providerAdminEditService,
  providerAdminEditSubCategory,
  providerAdminProfile,
  providerAdminSpecialists,
  providerAdminViewForm,
  providerOnboarding,
  providerOnboardingAddBranch,
  superAdminAddBranch,
  superAdminAddCampaign,
  superAdminAddCategory,
  superAdminAddProvider,
  superAdminAddService,
  superAdminAddSubCategory,
  superAdminAppointments,
  superAdminAppointmentsDetails,
  superAdminCampaigns,
  superAdminCategory,
  superAdminCompanyProviderDetails,
  superAdminCustomer,
  superAdminCustomerAppointmentDetails,
  superAdminCustomerDetails,
  superAdminDashboard,
  superAdminEditCategory,
  superAdminEditService,
  superAdminEditSubCategory,
  superAdminNewRequestProvider,
  superAdminOnHoldProvider,
  superAdminProvider,
  superAdminRequest,
  superAdminSalonProviderDetails,
  superAdminSpecialistProviderDetails,
} from 'routes/AppUrls';
import ProviderAdminDashboard from 'Pages/ProviderAdmin/providerDashboard';
import ProviderCustomer from 'Pages/ProviderAdmin/providerCustomer';
import ProviderCustomerDetails from 'Pages/ProviderAdmin/providerCustomer/providerCustomerDetail';
import ProviderAdminProfile from 'Pages/ProviderAdmin/providerProfile';
import ProviderAdminSpecialists from 'Pages/ProviderAdmin/Specialists';
import AddSpecialist from 'Pages/ProviderAdmin/Specialists/addSpecialists';
import ViewFormDetails from 'Pages/ProviderAdmin/Specialists/viewFormDetails';
import ProviderAdminBranches from 'Pages/ProviderAdmin/providerBranches';
import BranchesDetails from 'Pages/ProviderAdmin/providerBranches/branchesDetails';
import AddBranches from 'Pages/ProviderAdmin/providerBranches/addBranches';
import ProviderCategory from 'Pages/ProviderAdmin/ProviderService';
import ProviderAddSubCategory from 'Pages/ProviderAdmin/ProviderService/ProviderAddSubCategory';
import ProviderEditSubCategory from 'Pages/ProviderAdmin/ProviderService/ProviderEditSubCategory';
import ProviderAddService from 'Pages/ProviderAdmin/ProviderService/ProviderAddService';
import ProviderEditService from 'Pages/ProviderAdmin/ProviderService/ProviderEditService';
import ProviderAppointments from 'Pages/ProviderAdmin/providerAppointments';
import EditSchdeule from 'Pages/ProviderAdmin/providerAppointments/editSchdeule';

const publicRoutes = [
  {
    path: (locale) => getLandingURL(locale),
    element: <Login />,
    isNoSidebar: true,
    isNoNavbar: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => login(locale),
    element: <Login />,
    isNoSidebar: true,
    isNoNavbar: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => getStarted(locale),
    element: <GetStarted />,
    isNoSidebar: true,
    isNoNavbar: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => createPassword(locale),
    element: <CreatePassword />,
    isNoSidebar: true,
    isNoNavbar: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
];

const privateRoutes = [
  {
    path: (locale) => superAdminDashboard(locale),
    element: <Dashboard />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => providerAdminDashboard(locale),
    element: <ProviderAdminDashboard />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminRequest(locale),
    element: <Request />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => providerOnboarding(locale),
    element: <ProviderOnboarding />,
    isNoSidebar: true,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerOnboardingAddBranch(locale),
    element: <AddBranchOnboarding />,
    isNoSidebar: true,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminProvider(locale),
    element: <SuperAdminProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAddProvider(locale),
    element: <AddProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAddBranch(locale),
    element: <AddBranch />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminNewRequestProvider(locale),
    element: <NewRequestProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminOnHoldProvider(locale),
    element: <OnHoldProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminSpecialistProviderDetails(locale),
    element: <SpecialistDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminSalonProviderDetails(locale),
    element: <SalonDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminCompanyProviderDetails(locale),
    element: <CompanyDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminCustomer(locale),
    element: <Customer />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminCustomerDetails(locale),
    element: <CustomerDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAppointments(locale),
    element: <Appointments />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAppointmentsDetails(locale),
    element: <AppointmentsDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'super-admin',
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminCustomerAppointmentDetails(locale),
    element: <CustomerAppointmentDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminCategory(locale),
    element: <Categories />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAddCategory(locale),
    element: <AddCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAddSubCategory(locale),
    element: <AddSubCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminEditCategory(locale),
    element: <EditCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminEditSubCategory(locale),
    element: <EditSubCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAddService(locale),
    element: <AddService />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminEditService(locale),
    element: <EditService />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => providerAdminCategory(locale),
    element: <ProviderCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminAddSubCategory(locale),
    element: <ProviderAddSubCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminEditSubCategory(locale),
    element: <ProviderEditSubCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminAddService(locale),
    element: <ProviderAddService />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminEditService(locale),
    element: <ProviderEditService />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => superAdminCampaigns(locale),
    element: <Campaigns />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => superAdminAddCampaign(locale),
    element: <AddCampaign />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: ['super-admin'],
  },
  {
    path: (locale) => providerAdminCustomer(locale),
    element: <ProviderCustomer />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminCustomerDetails(locale),
    element: <ProviderCustomerDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminProfile(locale),
    element: <ProviderAdminProfile />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminSpecialists(locale),
    element: <ProviderAdminSpecialists />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminAddSpecialists(locale),
    element: <AddSpecialist />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminViewForm(locale),
    element: <ViewFormDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminBranches(locale),
    element: <ProviderAdminBranches />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminBranchesDetails(locale),
    element: <BranchesDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminAddBranches(locale),
    element: <AddBranches />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminAppointments(locale),
    element: <ProviderAppointments />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
  {
    path: (locale) => providerAdminEditAppointments(locale),
    element: <EditSchdeule />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: false,
    isPrivate: true,
    allowedRoles: [
      'specialist-admin',
      'salon-admin',
      'company-admin',
      'branch-company',
    ],
  },
];

const commonRoutes = [
  // {
  //   path: (locale) => aboutUs(locale),
  //   element: <AboutUs />,
  //   isNoSidebar: true,
  //   isCommon: true,
  //   isPrivate: true,
  // },
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];
